const sentir = {
    "verb": "sentir",
    "english": "to feel",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "siento",
                "spanish_verb_person": "Yo siento",
                "english": "I feel"
            },
            {
                "spanish_verb": "sientes",
                "spanish_verb_person": "T\u00fa sientes",
                "english": "You feel"
            },
            {
                "spanish_verb": "siente",
                "spanish_verb_person": "\u00c9l siente",
                "english": "He feels"
            },
            {
                "spanish_verb": "siente",
                "spanish_verb_person": "Ella siente",
                "english": "She feels"
            },
            {
                "spanish_verb": "siente",
                "spanish_verb_person": "Usted siente",
                "english": "You feel (formal)"
            },
            {
                "spanish_verb": "sentimos",
                "spanish_verb_person": "Nosotros/Nosotras sentimos",
                "english": "We feel"
            },
            {
                "spanish_verb": "sent\u00eds",
                "spanish_verb_person": "Vosotros/Vosotras sent\u00eds",
                "english": "You all feel"
            },
            {
                "spanish_verb": "sienten",
                "spanish_verb_person": "Ellos/Ellas sienten",
                "english": "They feel"
            },
            {
                "spanish_verb": "sienten",
                "spanish_verb_person": "Ustedes sienten",
                "english": "You all feel (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "sent\u00ed",
                "spanish_verb_person": "Yo sent\u00ed",
                "english": "I felt"
            },
            {
                "spanish_verb": "sentiste",
                "spanish_verb_person": "T\u00fa sentiste",
                "english": "You felt"
            },
            {
                "spanish_verb": "sinti\u00f3",
                "spanish_verb_person": "\u00c9l sinti\u00f3",
                "english": "He felt"
            },
            {
                "spanish_verb": "sinti\u00f3",
                "spanish_verb_person": "Ella sinti\u00f3",
                "english": "She felt"
            },
            {
                "spanish_verb": "sinti\u00f3",
                "spanish_verb_person": "Usted sinti\u00f3",
                "english": "You felt (formal)"
            },
            {
                "spanish_verb": "sentimos",
                "spanish_verb_person": "Nosotros/Nosotras sentimos",
                "english": "We felt"
            },
            {
                "spanish_verb": "sentisteis",
                "spanish_verb_person": "Vosotros/Vosotras sentisteis",
                "english": "You all felt"
            },
            {
                "spanish_verb": "sintieron",
                "spanish_verb_person": "Ellos/Ellas sintieron",
                "english": "They felt"
            },
            {
                "spanish_verb": "sintieron",
                "spanish_verb_person": "Ustedes sintieron",
                "english": "You all felt (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "sent\u00eda",
                "spanish_verb_person": "Yo sent\u00eda",
                "english": "I felt/I used to feel"
            },
            {
                "spanish_verb": "sent\u00edas",
                "spanish_verb_person": "T\u00fa sent\u00edas",
                "english": "You felt/You used to feel"
            },
            {
                "spanish_verb": "sent\u00eda",
                "spanish_verb_person": "\u00c9l sent\u00eda",
                "english": "He felt/He used to feel"
            },
            {
                "spanish_verb": "sent\u00eda",
                "spanish_verb_person": "Ella sent\u00eda",
                "english": "She felt/She used to feel"
            },
            {
                "spanish_verb": "sent\u00eda",
                "spanish_verb_person": "Usted sent\u00eda",
                "english": "You felt/You used to feel (formal)"
            },
            {
                "spanish_verb": "sent\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras sent\u00edamos",
                "english": "We felt/We used to feel"
            },
            {
                "spanish_verb": "sent\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras sent\u00edais",
                "english": "You all felt/You all used to feel"
            },
            {
                "spanish_verb": "sent\u00edan",
                "spanish_verb_person": "Ellos/Ellas sent\u00edan",
                "english": "They felt/They used to feel"
            },
            {
                "spanish_verb": "sent\u00edan",
                "spanish_verb_person": "Ustedes sent\u00edan",
                "english": "You all felt/You all used to feel (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "sentir\u00e9",
                "spanish_verb_person": "Yo sentir\u00e9",
                "english": "I will feel"
            },
            {
                "spanish_verb": "sentir\u00e1s",
                "spanish_verb_person": "T\u00fa sentir\u00e1s",
                "english": "You will feel"
            },
            {
                "spanish_verb": "sentir\u00e1",
                "spanish_verb_person": "\u00c9l sentir\u00e1",
                "english": "He will feel"
            },
            {
                "spanish_verb": "sentir\u00e1",
                "spanish_verb_person": "Ella sentir\u00e1",
                "english": "She will feel"
            },
            {
                "spanish_verb": "sentir\u00e1",
                "spanish_verb_person": "Usted sentir\u00e1",
                "english": "You will feel (formal)"
            },
            {
                "spanish_verb": "sentiremos",
                "spanish_verb_person": "Nosotros/Nosotras sentiremos",
                "english": "We will feel"
            },
            {
                "spanish_verb": "sentir\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras sentir\u00e9is",
                "english": "You all will feel"
            },
            {
                "spanish_verb": "sentir\u00e1n",
                "spanish_verb_person": "Ellos/Ellas sentir\u00e1n",
                "english": "They will feel"
            },
            {
                "spanish_verb": "sentir\u00e1n",
                "spanish_verb_person": "Ustedes sentir\u00e1n",
                "english": "You all will feel (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "sentir\u00eda",
                "spanish_verb_person": "Yo sentir\u00eda",
                "english": "I would feel"
            },
            {
                "spanish_verb": "sentir\u00edas",
                "spanish_verb_person": "T\u00fa sentir\u00edas",
                "english": "You would feel"
            },
            {
                "spanish_verb": "sentir\u00eda",
                "spanish_verb_person": "\u00c9l sentir\u00eda",
                "english": "He would feel"
            },
            {
                "spanish_verb": "sentir\u00eda",
                "spanish_verb_person": "Ella sentir\u00eda",
                "english": "She would feel"
            },
            {
                "spanish_verb": "sentir\u00eda",
                "spanish_verb_person": "Usted sentir\u00eda",
                "english": "You would feel (formal)"
            },
            {
                "spanish_verb": "sentir\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras sentir\u00edamos",
                "english": "We would feel"
            },
            {
                "spanish_verb": "sentir\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras sentir\u00edais",
                "english": "You all would feel"
            },
            {
                "spanish_verb": "sentir\u00edan",
                "spanish_verb_person": "Ellos/Ellas sentir\u00edan",
                "english": "They would feel"
            },
            {
                "spanish_verb": "sentir\u00edan",
                "spanish_verb_person": "Ustedes sentir\u00edan",
                "english": "You all would feel (formal)"
            }
        ]
    }
}
export default sentir;