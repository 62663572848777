const poder = {
    "verb": "poder",
    "english": "to be able to",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "puedo",
                "spanish_verb_person": "Yo puedo",
                "english": "I can/I am able to"
            },
            {
                "spanish_verb": "puedes",
                "spanish_verb_person": "T\u00fa puedes",
                "english": "You can/You are able to"
            },
            {
                "spanish_verb": "puede",
                "spanish_verb_person": "\u00c9l puede",
                "english": "He can/He is able to"
            },
            {
                "spanish_verb": "puede",
                "spanish_verb_person": "Ella puede",
                "english": "She can/She is able to"
            },
            {
                "spanish_verb": "puede",
                "spanish_verb_person": "Usted puede",
                "english": "You can/You are able to (formal)"
            },
            {
                "spanish_verb": "podemos",
                "spanish_verb_person": "Nosotros/Nosotras podemos",
                "english": "We can/We are able to"
            },
            {
                "spanish_verb": "pod\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras pod\u00e9is",
                "english": "You all can/You all are able to"
            },
            {
                "spanish_verb": "pueden",
                "spanish_verb_person": "Ellos/Ellas pueden",
                "english": "They can/They are able to"
            },
            {
                "spanish_verb": "pueden",
                "spanish_verb_person": "Ustedes pueden",
                "english": "You all can/You all are able to (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "pude",
                "spanish_verb_person": "Yo pude",
                "english": "I could/I was able to"
            },
            {
                "spanish_verb": "pudiste",
                "spanish_verb_person": "T\u00fa pudiste",
                "english": "You could/You were able to"
            },
            {
                "spanish_verb": "pudo",
                "spanish_verb_person": "\u00c9l pudo",
                "english": "He could/He was able to"
            },
            {
                "spanish_verb": "pudo",
                "spanish_verb_person": "Ella pudo",
                "english": "She could/She was able to"
            },
            {
                "spanish_verb": "pudo",
                "spanish_verb_person": "Usted pudo",
                "english": "You could/You were able to (formal)"
            },
            {
                "spanish_verb": "pudimos",
                "spanish_verb_person": "Nosotros/Nosotras pudimos",
                "english": "We could/We were able to"
            },
            {
                "spanish_verb": "pudisteis",
                "spanish_verb_person": "Vosotros/Vosotras pudisteis",
                "english": "You all could/You all were able to"
            },
            {
                "spanish_verb": "pudieron",
                "spanish_verb_person": "Ellos/Ellas pudieron",
                "english": "They could/They were able to"
            },
            {
                "spanish_verb": "pudieron",
                "spanish_verb_person": "Ustedes pudieron",
                "english": "You all could/You all were able to (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "pod\u00eda",
                "spanish_verb_person": "Yo pod\u00eda",
                "english": "I could/I used to be able to"
            },
            {
                "spanish_verb": "pod\u00edas",
                "spanish_verb_person": "T\u00fa pod\u00edas",
                "english": "You could/You used to be able to"
            },
            {
                "spanish_verb": "pod\u00eda",
                "spanish_verb_person": "\u00c9l pod\u00eda",
                "english": "He could/He used to be able to"
            },
            {
                "spanish_verb": "pod\u00eda",
                "spanish_verb_person": "Ella pod\u00eda",
                "english": "She could/She used to be able to"
            },
            {
                "spanish_verb": "pod\u00eda",
                "spanish_verb_person": "Usted pod\u00eda",
                "english": "You could/You used to be able to (formal)"
            },
            {
                "spanish_verb": "pod\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras pod\u00edamos",
                "english": "We could/We used to be able to"
            },
            {
                "spanish_verb": "pod\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras pod\u00edais",
                "english": "You all could/You all used to be able to"
            },
            {
                "spanish_verb": "pod\u00edan",
                "spanish_verb_person": "Ellos/Ellas pod\u00edan",
                "english": "They could/They used to be able to"
            },
            {
                "spanish_verb": "pod\u00edan",
                "spanish_verb_person": "Ustedes pod\u00edan",
                "english": "You all could/You all used to be able to (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "podr\u00e9",
                "spanish_verb_person": "Yo podr\u00e9",
                "english": "I will be able to"
            },
            {
                "spanish_verb": "podr\u00e1s",
                "spanish_verb_person": "T\u00fa podr\u00e1s",
                "english": "You will be able to"
            },
            {
                "spanish_verb": "podr\u00e1",
                "spanish_verb_person": "\u00c9l podr\u00e1",
                "english": "He will be able to"
            },
            {
                "spanish_verb": "podr\u00e1",
                "spanish_verb_person": "Ella podr\u00e1",
                "english": "She will be able to"
            },
            {
                "spanish_verb": "podr\u00e1",
                "spanish_verb_person": "Usted podr\u00e1",
                "english": "You will be able to (formal)"
            },
            {
                "spanish_verb": "podremos",
                "spanish_verb_person": "Nosotros/Nosotras podremos",
                "english": "We will be able to"
            },
            {
                "spanish_verb": "podr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras podr\u00e9is",
                "english": "You all will be able to"
            },
            {
                "spanish_verb": "podr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas podr\u00e1n",
                "english": "They will be able to"
            },
            {
                "spanish_verb": "podr\u00e1n",
                "spanish_verb_person": "Ustedes podr\u00e1n",
                "english": "You all will be able to (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "podr\u00eda",
                "spanish_verb_person": "Yo podr\u00eda",
                "english": "I could/I would be able to"
            },
            {
                "spanish_verb": "podr\u00edas",
                "spanish_verb_person": "T\u00fa podr\u00edas",
                "english": "You could/You would be able to"
            },
            {
                "spanish_verb": "podr\u00eda",
                "spanish_verb_person": "\u00c9l podr\u00eda",
                "english": "He could/He would be able to"
            },
            {
                "spanish_verb": "podr\u00eda",
                "spanish_verb_person": "Ella podr\u00eda",
                "english": "She could/She would be able to"
            },
            {
                "spanish_verb": "podr\u00eda",
                "spanish_verb_person": "Usted podr\u00eda",
                "english": "You could/You would be able to (formal)"
            },
            {
                "spanish_verb": "podr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras podr\u00edamos",
                "english": "We could/We would be able to"
            },
            {
                "spanish_verb": "podr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras podr\u00edais",
                "english": "You all could/You all would be able to"
            },
            {
                "spanish_verb": "podr\u00edan",
                "spanish_verb_person": "Ellos/Ellas podr\u00edan",
                "english": "They could/They would be able to"
            },
            {
                "spanish_verb": "podr\u00edan",
                "spanish_verb_person": "Ustedes podr\u00edan",
                "english": "You all could/You all would be able to (formal)"
            }
        ]
    }
}
export default poder;