const haber = {
    "verb": "haber",
    "english": "to have (auxilliary)",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "he",
                "spanish_verb_person": "Yo he",
                "english": "I have"
            },
            {
                "spanish_verb": "has",
                "spanish_verb_person": "T\u00fa has",
                "english": "You have"
            },
            {
                "spanish_verb": "ha",
                "spanish_verb_person": "\u00c9l ha",
                "english": "He has"
            },
            {
                "spanish_verb": "ha",
                "spanish_verb_person": "Ella ha",
                "english": "She has"
            },
            {
                "spanish_verb": "ha",
                "spanish_verb_person": "Usted ha",
                "english": "You have (formal)"
            },
            {
                "spanish_verb": "hemos",
                "spanish_verb_person": "Nosotros/Nosotras hemos",
                "english": "We have"
            },
            {
                "spanish_verb": "hab\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras hab\u00e9is",
                "english": "You all have"
            },
            {
                "spanish_verb": "han",
                "spanish_verb_person": "Ellos/Ellas han",
                "english": "They have"
            },
            {
                "spanish_verb": "han",
                "spanish_verb_person": "Ustedes han",
                "english": "You all have (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "hube",
                "spanish_verb_person": "Yo hube",
                "english": "I had"
            },
            {
                "spanish_verb": "hubiste",
                "spanish_verb_person": "T\u00fa hubiste",
                "english": "You had"
            },
            {
                "spanish_verb": "hubo",
                "spanish_verb_person": "\u00c9l hubo",
                "english": "He had"
            },
            {
                "spanish_verb": "hubo",
                "spanish_verb_person": "Ella hubo",
                "english": "She had"
            },
            {
                "spanish_verb": "hubo",
                "spanish_verb_person": "Usted hubo",
                "english": "You had (formal)"
            },
            {
                "spanish_verb": "hubimos",
                "spanish_verb_person": "Nosotros/Nosotras hubimos",
                "english": "We had"
            },
            {
                "spanish_verb": "hubisteis",
                "spanish_verb_person": "Vosotros/Vosotras hubisteis",
                "english": "You all had"
            },
            {
                "spanish_verb": "hubieron",
                "spanish_verb_person": "Ellos/Ellas hubieron",
                "english": "They had"
            },
            {
                "spanish_verb": "hubieron",
                "spanish_verb_person": "Ustedes hubieron",
                "english": "You all had (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "hab\u00eda",
                "spanish_verb_person": "Yo hab\u00eda",
                "english": "I had/I used to have"
            },
            {
                "spanish_verb": "hab\u00edas",
                "spanish_verb_person": "T\u00fa hab\u00edas",
                "english": "You had/You used to have"
            },
            {
                "spanish_verb": "hab\u00eda",
                "spanish_verb_person": "\u00c9l hab\u00eda",
                "english": "He had/He used to have"
            },
            {
                "spanish_verb": "hab\u00eda",
                "spanish_verb_person": "Ella hab\u00eda",
                "english": "She had/She used to have"
            },
            {
                "spanish_verb": "hab\u00eda",
                "spanish_verb_person": "Usted hab\u00eda",
                "english": "You had/You used to have (formal)"
            },
            {
                "spanish_verb": "hab\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras hab\u00edamos",
                "english": "We had/We used to have"
            },
            {
                "spanish_verb": "hab\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras hab\u00edais",
                "english": "You all had/You all used to have"
            },
            {
                "spanish_verb": "hab\u00edan",
                "spanish_verb_person": "Ellos/Ellas hab\u00edan",
                "english": "They had/They used to have"
            },
            {
                "spanish_verb": "hab\u00edan",
                "spanish_verb_person": "Ustedes hab\u00edan",
                "english": "You all had/You all used to have (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "habr\u00e9",
                "spanish_verb_person": "Yo habr\u00e9",
                "english": "I will have"
            },
            {
                "spanish_verb": "habr\u00e1s",
                "spanish_verb_person": "T\u00fa habr\u00e1s",
                "english": "You will have"
            },
            {
                "spanish_verb": "habr\u00e1",
                "spanish_verb_person": "\u00c9l habr\u00e1",
                "english": "He will have"
            },
            {
                "spanish_verb": "habr\u00e1",
                "spanish_verb_person": "Ella habr\u00e1",
                "english": "She will have"
            },
            {
                "spanish_verb": "habr\u00e1",
                "spanish_verb_person": "Usted habr\u00e1",
                "english": "You will have (formal)"
            },
            {
                "spanish_verb": "habremos",
                "spanish_verb_person": "Nosotros/Nosotras habremos",
                "english": "We will have"
            },
            {
                "spanish_verb": "habr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras habr\u00e9is",
                "english": "You all will have"
            },
            {
                "spanish_verb": "habr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas habr\u00e1n",
                "english": "They will have"
            },
            {
                "spanish_verb": "habr\u00e1n",
                "spanish_verb_person": "Ustedes habr\u00e1n",
                "english": "You all will have (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "habr\u00eda",
                "spanish_verb_person": "Yo habr\u00eda",
                "english": "I would have"
            },
            {
                "spanish_verb": "habr\u00edas",
                "spanish_verb_person": "T\u00fa habr\u00edas",
                "english": "You would have"
            },
            {
                "spanish_verb": "habr\u00eda",
                "spanish_verb_person": "\u00c9l habr\u00eda",
                "english": "He would have"
            },
            {
                "spanish_verb": "habr\u00eda",
                "spanish_verb_person": "Ella habr\u00eda",
                "english": "She would have"
            },
            {
                "spanish_verb": "habr\u00eda",
                "spanish_verb_person": "Usted habr\u00eda",
                "english": "You would have (formal)"
            },
            {
                "spanish_verb": "habr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras habr\u00edamos",
                "english": "We would have"
            },
            {
                "spanish_verb": "habr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras habr\u00edais",
                "english": "You all would have"
            },
            {
                "spanish_verb": "habr\u00edan",
                "spanish_verb_person": "Ellos/Ellas habr\u00edan",
                "english": "They would have"
            },
            {
                "spanish_verb": "habr\u00edan",
                "spanish_verb_person": "Ustedes habr\u00edan",
                "english": "You all would have (formal)"
            }
        ]
    }
}
export default haber;