const gustar = {
    "verb": "gustar",
    "english": "to like",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "gusto",
                "spanish_verb_person": "A m\u00ed me gusta",
                "english": "I like"
            },
            {
                "spanish_verb": "gustas",
                "spanish_verb_person": "A ti te gusta",
                "english": "You like"
            },
            {
                "spanish_verb": "gusta",
                "spanish_verb_person": "A \u00e9l le gusta",
                "english": "He likes"
            },
            {
                "spanish_verb": "gusta",
                "spanish_verb_person": "A ella le gusta",
                "english": "She likes"
            },
            {
                "spanish_verb": "gusta",
                "spanish_verb_person": "A usted le gusta",
                "english": "You like (formal)"
            },
            {
                "spanish_verb": "gustamos",
                "spanish_verb_person": "A nosotros nos gusta",
                "english": "We like"
            },
            {
                "spanish_verb": "gust\u00e1is",
                "spanish_verb_person": "A vosotros os gusta",
                "english": "You all like"
            },
            {
                "spanish_verb": "gustan",
                "spanish_verb_person": "A ellos/ellas les gusta",
                "english": "They like"
            },
            {
                "spanish_verb": "gustan",
                "spanish_verb_person": "A ustedes les gusta",
                "english": "You all like (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "gust\u00f3",
                "spanish_verb_person": "A m\u00ed me gust\u00f3",
                "english": "I liked"
            },
            {
                "spanish_verb": "gustaste",
                "spanish_verb_person": "A ti te gustaste",
                "english": "You liked"
            },
            {
                "spanish_verb": "gust\u00f3",
                "spanish_verb_person": "A \u00e9l le gust\u00f3",
                "english": "He liked"
            },
            {
                "spanish_verb": "gust\u00f3",
                "spanish_verb_person": "A ella le gust\u00f3",
                "english": "She liked"
            },
            {
                "spanish_verb": "gust\u00f3",
                "spanish_verb_person": "A usted le gust\u00f3",
                "english": "You liked (formal)"
            },
            {
                "spanish_verb": "gustamos",
                "spanish_verb_person": "A nosotros nos gust\u00f3",
                "english": "We liked"
            },
            {
                "spanish_verb": "gustasteis",
                "spanish_verb_person": "A vosotros os gustasteis",
                "english": "You all liked"
            },
            {
                "spanish_verb": "gustaron",
                "spanish_verb_person": "A ellos/ellas les gustaron",
                "english": "They liked"
            },
            {
                "spanish_verb": "gustaron",
                "spanish_verb_person": "A ustedes les gustaron",
                "english": "You all liked (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "gustaba",
                "spanish_verb_person": "A m\u00ed me gustaba",
                "english": "I liked/I used to like"
            },
            {
                "spanish_verb": "gustabas",
                "spanish_verb_person": "A ti te gustabas",
                "english": "You liked/You used to like"
            },
            {
                "spanish_verb": "gustaba",
                "spanish_verb_person": "A \u00e9l le gustaba",
                "english": "He liked/He used to like"
            },
            {
                "spanish_verb": "gustaba",
                "spanish_verb_person": "A ella le gustaba",
                "english": "She liked/She used to like"
            },
            {
                "spanish_verb": "gustaba",
                "spanish_verb_person": "A usted le gustaba",
                "english": "You liked/You used to like (formal)"
            },
            {
                "spanish_verb": "gust\u00e1bamos",
                "spanish_verb_person": "A nosotros nos gust\u00e1bamos",
                "english": "We liked/We used to like"
            },
            {
                "spanish_verb": "gustabais",
                "spanish_verb_person": "A vosotros os gustabais",
                "english": "You all liked/You all used to like"
            },
            {
                "spanish_verb": "gustaban",
                "spanish_verb_person": "A ellos/ellas les gustaban",
                "english": "They liked/They used to like"
            },
            {
                "spanish_verb": "gustaban",
                "spanish_verb_person": "A ustedes les gustaban",
                "english": "You all liked/You all used to like (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "gustar\u00e1",
                "spanish_verb_person": "A m\u00ed me gustar\u00e1",
                "english": "I will like"
            },
            {
                "spanish_verb": "gustar\u00e1s",
                "spanish_verb_person": "A ti te gustar\u00e1s",
                "english": "You will like"
            },
            {
                "spanish_verb": "gustar\u00e1",
                "spanish_verb_person": "A \u00e9l le gustar\u00e1",
                "english": "He will like"
            },
            {
                "spanish_verb": "gustar\u00e1",
                "spanish_verb_person": "A ella le gustar\u00e1",
                "english": "She will like"
            },
            {
                "spanish_verb": "gustar\u00e1",
                "spanish_verb_person": "A usted le gustar\u00e1",
                "english": "You will like (formal)"
            },
            {
                "spanish_verb": "gustaremos",
                "spanish_verb_person": "A nosotros nos gustaremos",
                "english": "We will like"
            },
            {
                "spanish_verb": "gustar\u00e9is",
                "spanish_verb_person": "A vosotros os gustar\u00e9is",
                "english": "You all will like"
            },
            {
                "spanish_verb": "gustar\u00e1n",
                "spanish_verb_person": "A ellos/ellas les gustar\u00e1n",
                "english": "They will like"
            },
            {
                "spanish_verb": "gustar\u00e1n",
                "spanish_verb_person": "A ustedes les gustar\u00e1n",
                "english": "You all will like (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "gustar\u00eda",
                "spanish_verb_person": "A m\u00ed me gustar\u00eda",
                "english": "I would like"
            },
            {
                "spanish_verb": "gustar\u00edas",
                "spanish_verb_person": "A ti te gustar\u00edas",
                "english": "You would like"
            },
            {
                "spanish_verb": "gustar\u00eda",
                "spanish_verb_person": "A \u00e9l le gustar\u00eda",
                "english": "He would like"
            },
            {
                "spanish_verb": "gustar\u00eda",
                "spanish_verb_person": "A ella le gustar\u00eda",
                "english": "She would like"
            },
            {
                "spanish_verb": "gustar\u00eda",
                "spanish_verb_person": "A usted le gustar\u00eda",
                "english": "You would like (formal)"
            },
            {
                "spanish_verb": "gustar\u00edamos",
                "spanish_verb_person": "A nosotros nos gustar\u00edamos",
                "english": "We would like"
            },
            {
                "spanish_verb": "gustar\u00edais",
                "spanish_verb_person": "A vosotros os gustar\u00edais",
                "english": "You all would like"
            },
            {
                "spanish_verb": "gustar\u00edan",
                "spanish_verb_person": "A ellos/ellas les gustar\u00edan",
                "english": "They would like"
            },
            {
                "spanish_verb": "gustar\u00edan",
                "spanish_verb_person": "A ustedes les gustar\u00edan",
                "english": "You all would like (formal)"
            }
        ]
    }
}
export default gustar;