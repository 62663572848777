const vivir = {
    "verb": "vivir",
    "english": "to live",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "vivo",
                "spanish_verb_person": "Yo vivo",
                "english": "I live"
            },
            {
                "spanish_verb": "vives",
                "spanish_verb_person": "T\u00fa vives",
                "english": "You live"
            },
            {
                "spanish_verb": "vive",
                "spanish_verb_person": "\u00c9l vive",
                "english": "He lives"
            },
            {
                "spanish_verb": "vive",
                "spanish_verb_person": "Ella vive",
                "english": "She lives"
            },
            {
                "spanish_verb": "vive",
                "spanish_verb_person": "Usted vive",
                "english": "You live (formal)"
            },
            {
                "spanish_verb": "vivimos",
                "spanish_verb_person": "Nosotros/Nosotras vivimos",
                "english": "We live"
            },
            {
                "spanish_verb": "viv\u00eds",
                "spanish_verb_person": "Vosotros/Vosotras viv\u00eds",
                "english": "You all live"
            },
            {
                "spanish_verb": "viven",
                "spanish_verb_person": "Ellos/Ellas viven",
                "english": "They live"
            },
            {
                "spanish_verb": "viven",
                "spanish_verb_person": "Ustedes viven",
                "english": "You all live (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "viv\u00ed",
                "spanish_verb_person": "Yo viv\u00ed",
                "english": "I lived"
            },
            {
                "spanish_verb": "viviste",
                "spanish_verb_person": "T\u00fa viviste",
                "english": "You lived"
            },
            {
                "spanish_verb": "vivi\u00f3",
                "spanish_verb_person": "\u00c9l vivi\u00f3",
                "english": "He lived"
            },
            {
                "spanish_verb": "vivi\u00f3",
                "spanish_verb_person": "Ella vivi\u00f3",
                "english": "She lived"
            },
            {
                "spanish_verb": "vivi\u00f3",
                "spanish_verb_person": "Usted vivi\u00f3",
                "english": "You lived (formal)"
            },
            {
                "spanish_verb": "vivimos",
                "spanish_verb_person": "Nosotros/Nosotras vivimos",
                "english": "We lived"
            },
            {
                "spanish_verb": "vivisteis",
                "spanish_verb_person": "Vosotros/Vosotras vivisteis",
                "english": "You all lived"
            },
            {
                "spanish_verb": "vivieron",
                "spanish_verb_person": "Ellos/Ellas vivieron",
                "english": "They lived"
            },
            {
                "spanish_verb": "vivieron",
                "spanish_verb_person": "Ustedes vivieron",
                "english": "You all lived (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "viv\u00eda",
                "spanish_verb_person": "Yo viv\u00eda",
                "english": "I lived/I used to live"
            },
            {
                "spanish_verb": "viv\u00edas",
                "spanish_verb_person": "T\u00fa viv\u00edas",
                "english": "You lived/You used to live"
            },
            {
                "spanish_verb": "viv\u00eda",
                "spanish_verb_person": "\u00c9l viv\u00eda",
                "english": "He lived/He used to live"
            },
            {
                "spanish_verb": "viv\u00eda",
                "spanish_verb_person": "Ella viv\u00eda",
                "english": "She lived/She used to live"
            },
            {
                "spanish_verb": "viv\u00eda",
                "spanish_verb_person": "Usted viv\u00eda",
                "english": "You lived/You used to live (formal)"
            },
            {
                "spanish_verb": "viv\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras viv\u00edamos",
                "english": "We lived/We used to live"
            },
            {
                "spanish_verb": "viv\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras viv\u00edais",
                "english": "You all lived/You all used to live"
            },
            {
                "spanish_verb": "viv\u00edan",
                "spanish_verb_person": "Ellos/Ellas viv\u00edan",
                "english": "They lived/They used to live"
            },
            {
                "spanish_verb": "viv\u00edan",
                "spanish_verb_person": "Ustedes viv\u00edan",
                "english": "You all lived/You all used to live (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "vivir\u00e9",
                "spanish_verb_person": "Yo vivir\u00e9",
                "english": "I will live"
            },
            {
                "spanish_verb": "vivir\u00e1s",
                "spanish_verb_person": "T\u00fa vivir\u00e1s",
                "english": "You will live"
            },
            {
                "spanish_verb": "vivir\u00e1",
                "spanish_verb_person": "\u00c9l vivir\u00e1",
                "english": "He will live"
            },
            {
                "spanish_verb": "vivir\u00e1",
                "spanish_verb_person": "Ella vivir\u00e1",
                "english": "She will live"
            },
            {
                "spanish_verb": "vivir\u00e1",
                "spanish_verb_person": "Usted vivir\u00e1",
                "english": "You will live (formal)"
            },
            {
                "spanish_verb": "viviremos",
                "spanish_verb_person": "Nosotros/Nosotras viviremos",
                "english": "We will live"
            },
            {
                "spanish_verb": "vivir\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras vivir\u00e9is",
                "english": "You all will live"
            },
            {
                "spanish_verb": "vivir\u00e1n",
                "spanish_verb_person": "Ellos/Ellas vivir\u00e1n",
                "english": "They will live"
            },
            {
                "spanish_verb": "vivir\u00e1n",
                "spanish_verb_person": "Ustedes vivir\u00e1n",
                "english": "You all will live (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "vivir\u00eda",
                "spanish_verb_person": "Yo vivir\u00eda",
                "english": "I would live"
            },
            {
                "spanish_verb": "vivir\u00edas",
                "spanish_verb_person": "T\u00fa vivir\u00edas",
                "english": "You would live"
            },
            {
                "spanish_verb": "vivir\u00eda",
                "spanish_verb_person": "\u00c9l vivir\u00eda",
                "english": "He would live"
            },
            {
                "spanish_verb": "vivir\u00eda",
                "spanish_verb_person": "Ella vivir\u00eda",
                "english": "She would live"
            },
            {
                "spanish_verb": "vivir\u00eda",
                "spanish_verb_person": "Usted vivir\u00eda",
                "english": "You would live (formal)"
            },
            {
                "spanish_verb": "vivir\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras vivir\u00edamos",
                "english": "We would live"
            },
            {
                "spanish_verb": "vivir\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras vivir\u00edais",
                "english": "You all would live"
            },
            {
                "spanish_verb": "vivir\u00edan",
                "spanish_verb_person": "Ellos/Ellas vivir\u00edan",
                "english": "They would live"
            },
            {
                "spanish_verb": "vivir\u00edan",
                "spanish_verb_person": "Ustedes vivir\u00edan",
                "english": "You all would live (formal)"
            }
        ]
    }
}
export default vivir;