import ser from './jsObjects/ser.js'
import estar from './jsObjects/estar.js'
import tener from './jsObjects/tener.js'
import hacer from './jsObjects/hacer.js'
import ir from './jsObjects/ir.js'
import decir from './jsObjects/decir.js'
import ver from './jsObjects/ver.js'
import poder from  './jsObjects/poder.js'
import querer from './jsObjects/querer.js'
import dar from './jsObjects/dar.js'
import saber from './jsObjects/saber.js'
import llegar from './jsObjects/llegar.js'
import pasar from './jsObjects/pasar.js'
import gustar from './jsObjects/gustar.js'
import haber from './jsObjects/haber.js'
import venir from './jsObjects/venir.js'
import vivir from './jsObjects/vivir.js'
import sentir from './jsObjects/sentir.js'
import pensar from './jsObjects/pensar.js'
import encontrar from './jsObjects/encontrar.js'


const verbObjectList = [
  ser, // 1
  estar, // 2
  tener, // 3
  hacer, // 4
  ir, // 5
  decir, // 6
  ver, // 7
  poder, // 8
  querer, // 9
  dar, // 10
  saber, // 11
  llegar, // 12
  pasar, // 13
  gustar, // 14
  haber, // 15
  venir, // 16
  vivir, // 17
  sentir, // 18
  pensar, // 19
  encontrar // 20
];

const VERB = 'verb';
const verbMap = new Map();
verbObjectList.forEach(verb => {
  verbMap.set(verb[VERB], verb);
});

export default verbMap;