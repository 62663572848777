const querer = {
    "verb": "querer",
    "english": "to want",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "quiero",
                "spanish_verb_person": "Yo quiero",
                "english": "I want"
            },
            {
                "spanish_verb": "quieres",
                "spanish_verb_person": "T\u00fa quieres",
                "english": "You want"
            },
            {
                "spanish_verb": "quiere",
                "spanish_verb_person": "\u00c9l quiere",
                "english": "He wants"
            },
            {
                "spanish_verb": "quiere",
                "spanish_verb_person": "Ella quiere",
                "english": "She wants"
            },
            {
                "spanish_verb": "quiere",
                "spanish_verb_person": "Usted quiere",
                "english": "You want (formal)"
            },
            {
                "spanish_verb": "queremos",
                "spanish_verb_person": "Nosotros/Nosotras queremos",
                "english": "We want"
            },
            {
                "spanish_verb": "quer\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras quer\u00e9is",
                "english": "You all want"
            },
            {
                "spanish_verb": "quieren",
                "spanish_verb_person": "Ellos/Ellas quieren",
                "english": "They want"
            },
            {
                "spanish_verb": "quieren",
                "spanish_verb_person": "Ustedes quieren",
                "english": "You all want (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "quise",
                "spanish_verb_person": "Yo quise",
                "english": "I wanted"
            },
            {
                "spanish_verb": "quisiste",
                "spanish_verb_person": "T\u00fa quisiste",
                "english": "You wanted"
            },
            {
                "spanish_verb": "quiso",
                "spanish_verb_person": "\u00c9l quiso",
                "english": "He wanted"
            },
            {
                "spanish_verb": "quiso",
                "spanish_verb_person": "Ella quiso",
                "english": "She wanted"
            },
            {
                "spanish_verb": "quiso",
                "spanish_verb_person": "Usted quiso",
                "english": "You wanted (formal)"
            },
            {
                "spanish_verb": "quisimos",
                "spanish_verb_person": "Nosotros/Nosotras quisimos",
                "english": "We wanted"
            },
            {
                "spanish_verb": "quisisteis",
                "spanish_verb_person": "Vosotros/Vosotras quisisteis",
                "english": "You all wanted"
            },
            {
                "spanish_verb": "quisieron",
                "spanish_verb_person": "Ellos/Ellas quisieron",
                "english": "They wanted"
            },
            {
                "spanish_verb": "quisieron",
                "spanish_verb_person": "Ustedes quisieron",
                "english": "You all wanted (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "quer\u00eda",
                "spanish_verb_person": "Yo quer\u00eda",
                "english": "I wanted/I used to want"
            },
            {
                "spanish_verb": "quer\u00edas",
                "spanish_verb_person": "T\u00fa quer\u00edas",
                "english": "You wanted/You used to want"
            },
            {
                "spanish_verb": "quer\u00eda",
                "spanish_verb_person": "\u00c9l quer\u00eda",
                "english": "He wanted/He used to want"
            },
            {
                "spanish_verb": "quer\u00eda",
                "spanish_verb_person": "Ella quer\u00eda",
                "english": "She wanted/She used to want"
            },
            {
                "spanish_verb": "quer\u00eda",
                "spanish_verb_person": "Usted quer\u00eda",
                "english": "You wanted/You used to want (formal)"
            },
            {
                "spanish_verb": "quer\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras quer\u00edamos",
                "english": "We wanted/We used to want"
            },
            {
                "spanish_verb": "quer\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras quer\u00edais",
                "english": "You all wanted/You all used to want"
            },
            {
                "spanish_verb": "quer\u00edan",
                "spanish_verb_person": "Ellos/Ellas quer\u00edan",
                "english": "They wanted/They used to want"
            },
            {
                "spanish_verb": "quer\u00edan",
                "spanish_verb_person": "Ustedes quer\u00edan",
                "english": "You all wanted/You all used to want (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "querr\u00e9",
                "spanish_verb_person": "Yo querr\u00e9",
                "english": "I will want"
            },
            {
                "spanish_verb": "querr\u00e1s",
                "spanish_verb_person": "T\u00fa querr\u00e1s",
                "english": "You will want"
            },
            {
                "spanish_verb": "querr\u00e1",
                "spanish_verb_person": "\u00c9l querr\u00e1",
                "english": "He will want"
            },
            {
                "spanish_verb": "querr\u00e1",
                "spanish_verb_person": "Ella querr\u00e1",
                "english": "She will want"
            },
            {
                "spanish_verb": "querr\u00e1",
                "spanish_verb_person": "Usted querr\u00e1",
                "english": "You will want (formal)"
            },
            {
                "spanish_verb": "querremos",
                "spanish_verb_person": "Nosotros/Nosotras querremos",
                "english": "We will want"
            },
            {
                "spanish_verb": "querr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras querr\u00e9is",
                "english": "You all will want"
            },
            {
                "spanish_verb": "querr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas querr\u00e1n",
                "english": "They will want"
            },
            {
                "spanish_verb": "querr\u00e1n",
                "spanish_verb_person": "Ustedes querr\u00e1n",
                "english": "You all will want (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "querr\u00eda",
                "spanish_verb_person": "Yo querr\u00eda",
                "english": "I would want"
            },
            {
                "spanish_verb": "querr\u00edas",
                "spanish_verb_person": "T\u00fa querr\u00edas",
                "english": "You would want"
            },
            {
                "spanish_verb": "querr\u00eda",
                "spanish_verb_person": "\u00c9l querr\u00eda",
                "english": "He would want"
            },
            {
                "spanish_verb": "querr\u00eda",
                "spanish_verb_person": "Ella querr\u00eda",
                "english": "She would want"
            },
            {
                "spanish_verb": "querr\u00eda",
                "spanish_verb_person": "Usted querr\u00eda",
                "english": "You would want (formal)"
            },
            {
                "spanish_verb": "querr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras querr\u00edamos",
                "english": "We would want"
            },
            {
                "spanish_verb": "querr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras querr\u00edais",
                "english": "You all would want"
            },
            {
                "spanish_verb": "querr\u00edan",
                "spanish_verb_person": "Ellos/Ellas querr\u00edan",
                "english": "They would want"
            },
            {
                "spanish_verb": "querr\u00edan",
                "spanish_verb_person": "Ustedes querr\u00edan",
                "english": "You all would want (formal)"
            }
        ]
    }
}
export default querer;