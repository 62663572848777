import './App.css';
import CardArea from './components/CardArea/CardArea.jsx';
import ControlCenter from './components/ControlCenter/ControlCenter.jsx';
import VerbDropdown from './components/VerbDropdown/VerbDropdown.jsx';
import verbear from './verbear.svg';

function App() {
  return (
    <div className="app">
      <header className={"header"}>
        <div className={"title-container"}>
          <img src={verbear} alt="verbear logo" className={"verbear"}/>
          <h1 className="title">Verbear</h1>
          <h2 className="title">Learn Spanish Verb Conjugations</h2>
        </div>
      </header>
      <section className={"verb-selection-container"}>
        <VerbDropdown/>
      </section>
      <section className={"control-container"}>
        <ControlCenter/>
      </section>
      <section className={"card-area"}>
        <CardArea/>
      </section>
      <footer className={"footer"}>
      <div className="container">
        <p>Spanish verb conjugation flashcards online</p>
        <p>&copy; 2023 Verbear. All rights reserved.</p>
      </div>
      </footer>
    </div>
  );
}

export default App;