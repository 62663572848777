const tener = {
    "verb": "tener",
    "english": "to have",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "tengo",
                "spanish_verb_person": "Yo tengo",
                "english": "I have"
            },
            {
                "spanish_verb": "tienes",
                "spanish_verb_person": "T\u00fa tienes",
                "english": "You have"
            },
            {
                "spanish_verb": "tiene",
                "spanish_verb_person": "\u00c9l tiene",
                "english": "He has"
            },
            {
                "spanish_verb": "tiene",
                "spanish_verb_person": "Ella tiene",
                "english": "She has"
            },
            {
                "spanish_verb": "tiene",
                "spanish_verb_person": "Usted tiene",
                "english": "You have (formal)"
            },
            {
                "spanish_verb": "tenemos",
                "spanish_verb_person": "Nosotros/Nosotras tenemos",
                "english": "We have"
            },
            {
                "spanish_verb": "ten\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras ten\u00e9is",
                "english": "You all have"
            },
            {
                "spanish_verb": "tienen",
                "spanish_verb_person": "Ellos/Ellas tienen",
                "english": "They have"
            },
            {
                "spanish_verb": "tienen",
                "spanish_verb_person": "Ustedes tienen",
                "english": "You all have (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "tuve",
                "spanish_verb_person": "Yo tuve",
                "english": "I had"
            },
            {
                "spanish_verb": "tuviste",
                "spanish_verb_person": "T\u00fa tuviste",
                "english": "You had"
            },
            {
                "spanish_verb": "tuvo",
                "spanish_verb_person": "\u00c9l tuvo",
                "english": "He had"
            },
            {
                "spanish_verb": "tuvo",
                "spanish_verb_person": "Ella tuvo",
                "english": "She had"
            },
            {
                "spanish_verb": "tuvo",
                "spanish_verb_person": "Usted tuvo",
                "english": "You had (formal)"
            },
            {
                "spanish_verb": "tuvimos",
                "spanish_verb_person": "Nosotros/Nosotras tuvimos",
                "english": "We had"
            },
            {
                "spanish_verb": "tuvisteis",
                "spanish_verb_person": "Vosotros/Vosotras tuvisteis",
                "english": "You all had"
            },
            {
                "spanish_verb": "tuvieron",
                "spanish_verb_person": "Ellos/Ellas tuvieron",
                "english": "They had"
            },
            {
                "spanish_verb": "tuvieron",
                "spanish_verb_person": "Ustedes tuvieron",
                "english": "You all had (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "ten\u00eda",
                "spanish_verb_person": "Yo ten\u00eda",
                "english": "I had/I was having"
            },
            {
                "spanish_verb": "ten\u00edas",
                "spanish_verb_person": "T\u00fa ten\u00edas",
                "english": "You had/You were having"
            },
            {
                "spanish_verb": "ten\u00eda",
                "spanish_verb_person": "\u00c9l ten\u00eda",
                "english": "He had/He was having"
            },
            {
                "spanish_verb": "ten\u00eda",
                "spanish_verb_person": "Ella ten\u00eda",
                "english": "She had/She was having"
            },
            {
                "spanish_verb": "ten\u00eda",
                "spanish_verb_person": "Usted ten\u00eda",
                "english": "You had (formal)/You were having"
            },
            {
                "spanish_verb": "ten\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ten\u00edamos",
                "english": "We had/We here having"
            },
            {
                "spanish_verb": "ten\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ten\u00edais",
                "english": "You all had/You all were having"
            },
            {
                "spanish_verb": "ten\u00edan",
                "spanish_verb_person": "Ellos/Ellas ten\u00edan",
                "english": "They had/They were having"
            },
            {
                "spanish_verb": "ten\u00edan",
                "spanish_verb_person": "Ustedes ten\u00edan",
                "english": "You all had/You were having (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "tendr\u00e9",
                "spanish_verb_person": "Yo tendr\u00e9",
                "english": "I will have"
            },
            {
                "spanish_verb": "tendr\u00e1s",
                "spanish_verb_person": "T\u00fa tendr\u00e1s",
                "english": "You will have"
            },
            {
                "spanish_verb": "tendr\u00e1",
                "spanish_verb_person": "\u00c9l tendr\u00e1",
                "english": "He will have"
            },
            {
                "spanish_verb": "tendr\u00e1",
                "spanish_verb_person": "Ella tendr\u00e1",
                "english": "She will have"
            },
            {
                "spanish_verb": "tendr\u00e1",
                "spanish_verb_person": "Usted tendr\u00e1",
                "english": "You will have (formal)"
            },
            {
                "spanish_verb": "tendremos",
                "spanish_verb_person": "Nosotros/Nosotras tendremos",
                "english": "We will have"
            },
            {
                "spanish_verb": "tendr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras tendr\u00e9is",
                "english": "You all will have"
            },
            {
                "spanish_verb": "tendr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas tendr\u00e1n",
                "english": "They will have"
            },
            {
                "spanish_verb": "tendr\u00e1n",
                "spanish_verb_person": "Ustedes tendr\u00e1n",
                "english": "You all will have (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "tendr\u00eda",
                "spanish_verb_person": "Yo tendr\u00eda",
                "english": "I would have"
            },
            {
                "spanish_verb": "tendr\u00edas",
                "spanish_verb_person": "T\u00fa tendr\u00edas",
                "english": "You would have"
            },
            {
                "spanish_verb": "tendr\u00eda",
                "spanish_verb_person": "\u00c9l tendr\u00eda",
                "english": "He would have"
            },
            {
                "spanish_verb": "tendr\u00eda",
                "spanish_verb_person": "Ella tendr\u00eda",
                "english": "She would have"
            },
            {
                "spanish_verb": "tendr\u00eda",
                "spanish_verb_person": "Usted tendr\u00eda",
                "english": "You would have (formal)"
            },
            {
                "spanish_verb": "tendr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras tendr\u00edamos",
                "english": "We would have"
            },
            {
                "spanish_verb": "tendr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras tendr\u00edais",
                "english": "You all would have"
            },
            {
                "spanish_verb": "tendr\u00edan",
                "spanish_verb_person": "Ellos/Ellas tendr\u00edan",
                "english": "They would have"
            },
            {
                "spanish_verb": "tendr\u00edan",
                "spanish_verb_person": "Ustedes tendr\u00edan",
                "english": "You all would have (formal)"
            }
        ]
    }
}
export default tener;