import React from 'react'
import styles from './CardArea.module.scss'
import Card from '../Card/Card.jsx'
import { useSelector } from 'react-redux'
import verbMap from '../../conjugations/verbMap'
import shuffleArray from '../../utility/shuffler'

function createDeck(verb, tensesToShow, shuffle = false) {
  const verbObject = verbMap.get(verb);
  let deck = [];
  for (let tense of tensesToShow) {
    const verbs = verbObject["conjugations"][tense].map(verbObj => {
      verbObj["tense"] = tense;
      return verbObj;
    });
    deck = [...deck, ...verbs];
  }
  if (shuffle) {
    shuffleArray(deck);
  }
  return deck;
}

function CardArea() {
  const verbSlice = useSelector(state => state.verbSlice);
  const controlSlice = useSelector(state => state.controlSlice);
  const { tenseControls, otherControls } = controlSlice;
  const tensesToShow = tenseControls.filter(control => control.checked).map(control => control.id);
  const shuffle = otherControls.find(control => control.id === 'shuffle').checked;
  const englishInFront = otherControls.find(control => control.id === 'englishInFront').checked;
  const deck = createDeck(verbSlice.selectedVerb, tensesToShow, shuffle);

  return (
    <div className={styles['card-area']}>
      <div className={styles['verbear-image']}></div>
      <span className={styles['card-count']}>cards : {deck.length} </span>
      <div className={styles['card-area']}>
       { deck.map((card, index) => 
         <Card
           key={`${index}_${card["spanish_verb"]}`}
           spanish_verb_person={card["spanish_verb_person"]} 
           english={card["english"]}
           tense={card["tense"]}
           flipped={englishInFront}
          />)
        }
      </div>
    </div>
  )
}

export default CardArea;