const saber = {
    "verb": "saber",
    "english": "to know",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "s\u00e9",
                "spanish_verb_person": "Yo s\u00e9",
                "english": "I know"
            },
            {
                "spanish_verb": "sabes",
                "spanish_verb_person": "T\u00fa sabes",
                "english": "You know"
            },
            {
                "spanish_verb": "sabe",
                "spanish_verb_person": "\u00c9l sabe",
                "english": "He knows"
            },
            {
                "spanish_verb": "sabe",
                "spanish_verb_person": "Ella sabe",
                "english": "She knows"
            },
            {
                "spanish_verb": "sabe",
                "spanish_verb_person": "Usted sabe",
                "english": "You know (formal)"
            },
            {
                "spanish_verb": "sabemos",
                "spanish_verb_person": "Nosotros/Nosotras sabemos",
                "english": "We know"
            },
            {
                "spanish_verb": "sab\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras sab\u00e9is",
                "english": "You all know"
            },
            {
                "spanish_verb": "saben",
                "spanish_verb_person": "Ellos/Ellas saben",
                "english": "They know"
            },
            {
                "spanish_verb": "saben",
                "spanish_verb_person": "Ustedes saben",
                "english": "You all know (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "supe",
                "spanish_verb_person": "Yo supe",
                "english": "I knew"
            },
            {
                "spanish_verb": "supiste",
                "spanish_verb_person": "T\u00fa supiste",
                "english": "You knew"
            },
            {
                "spanish_verb": "supo",
                "spanish_verb_person": "\u00c9l supo",
                "english": "He knew"
            },
            {
                "spanish_verb": "supo",
                "spanish_verb_person": "Ella supo",
                "english": "She knew"
            },
            {
                "spanish_verb": "supo",
                "spanish_verb_person": "Usted supo",
                "english": "You knew (formal)"
            },
            {
                "spanish_verb": "supimos",
                "spanish_verb_person": "Nosotros/Nosotras supimos",
                "english": "We knew"
            },
            {
                "spanish_verb": "supisteis",
                "spanish_verb_person": "Vosotros/Vosotras supisteis",
                "english": "You all knew"
            },
            {
                "spanish_verb": "supieron",
                "spanish_verb_person": "Ellos/Ellas supieron",
                "english": "They knew"
            },
            {
                "spanish_verb": "supieron",
                "spanish_verb_person": "Ustedes supieron",
                "english": "You all knew (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "sab\u00eda",
                "spanish_verb_person": "Yo sab\u00eda",
                "english": "I knew/I used to know"
            },
            {
                "spanish_verb": "sab\u00edas",
                "spanish_verb_person": "T\u00fa sab\u00edas",
                "english": "You knew/You used to know"
            },
            {
                "spanish_verb": "sab\u00eda",
                "spanish_verb_person": "\u00c9l sab\u00eda",
                "english": "He knew"
            },
            {
                "spanish_verb": "sab\u00eda",
                "spanish_verb_person": "Ella sab\u00eda",
                "english": "She knew"
            },
            {
                "spanish_verb": "sab\u00eda",
                "spanish_verb_person": "Usted sab\u00eda",
                "english": "You knew (formal)"
            },
            {
                "spanish_verb": "sab\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras sab\u00edamos",
                "english": "We knew/We used to know"
            },
            {
                "spanish_verb": "sab\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras sab\u00edais",
                "english": "You all knew/You all used to know"
            },
            {
                "spanish_verb": "sab\u00edan",
                "spanish_verb_person": "Ellos/Ellas sab\u00edan",
                "english": "They knew"
            },
            {
                "spanish_verb": "sab\u00edan",
                "spanish_verb_person": "Ustedes sab\u00edan",
                "english": "You all knew (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "sabr\u00e9",
                "spanish_verb_person": "Yo sabr\u00e9",
                "english": "I will know"
            },
            {
                "spanish_verb": "sabr\u00e1s",
                "spanish_verb_person": "T\u00fa sabr\u00e1s",
                "english": "You will know"
            },
            {
                "spanish_verb": "sabr\u00e1",
                "spanish_verb_person": "\u00c9l sabr\u00e1",
                "english": "He will know"
            },
            {
                "spanish_verb": "sabr\u00e1",
                "spanish_verb_person": "Ella sabr\u00e1",
                "english": "She will know"
            },
            {
                "spanish_verb": "sabr\u00e1",
                "spanish_verb_person": "Usted sabr\u00e1",
                "english": "You will know (formal)"
            },
            {
                "spanish_verb": "sabremos",
                "spanish_verb_person": "Nosotros/Nosotras sabremos",
                "english": "We will know"
            },
            {
                "spanish_verb": "sabr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras sabr\u00e9is",
                "english": "You all will know"
            },
            {
                "spanish_verb": "sabr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas sabr\u00e1n",
                "english": "They will know"
            },
            {
                "spanish_verb": "sabr\u00e1n",
                "spanish_verb_person": "Ustedes sabr\u00e1n",
                "english": "You all will know (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "sabr\u00eda",
                "spanish_verb_person": "Yo sabr\u00eda",
                "english": "I would know"
            },
            {
                "spanish_verb": "sabr\u00edas",
                "spanish_verb_person": "T\u00fa sabr\u00edas",
                "english": "You would know"
            },
            {
                "spanish_verb": "sabr\u00eda",
                "spanish_verb_person": "\u00c9l sabr\u00eda",
                "english": "He would know"
            },
            {
                "spanish_verb": "sabr\u00eda",
                "spanish_verb_person": "Ella sabr\u00eda",
                "english": "She would know"
            },
            {
                "spanish_verb": "sabr\u00eda",
                "spanish_verb_person": "Usted sabr\u00eda",
                "english": "You would know (formal)"
            },
            {
                "spanish_verb": "sabr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras sabr\u00edamos",
                "english": "We would know"
            },
            {
                "spanish_verb": "sabr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras sabr\u00edais",
                "english": "You all would know"
            },
            {
                "spanish_verb": "sabr\u00edan",
                "spanish_verb_person": "Ellos/Ellas sabr\u00edan",
                "english": "They would know"
            },
            {
                "spanish_verb": "sabr\u00edan",
                "spanish_verb_person": "Ustedes sabr\u00edan",
                "english": "You all would know (formal)"
            }
        ]
    }
}
export default saber;