const hacer = {
    "verb": "hacer",
    "english": "hacer",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "hago",
                "spanish_verb_person": "Yo hago",
                "english": "I do/ I make"
            },
            {
                "spanish_verb": "haces",
                "spanish_verb_person": "T\u00fa haces",
                "english": "You do/You make"
            },
            {
                "spanish_verb": "hace",
                "spanish_verb_person": "\u00c9l hace",
                "english": "He does/He makes"
            },
            {
                "spanish_verb": "hace",
                "spanish_verb_person": "Ella hace",
                "english": "She does/She makes"
            },
            {
                "spanish_verb": "hace",
                "spanish_verb_person": "Usted hace",
                "english": "You do/You make (formal)"
            },
            {
                "spanish_verb": "hacemos",
                "spanish_verb_person": "Nosotros/Nosotras hacemos",
                "english": "We do/We make"
            },
            {
                "spanish_verb": "hac\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras hac\u00e9is",
                "english": "You all do/You all make"
            },
            {
                "spanish_verb": "hacen",
                "spanish_verb_person": "Ellos/Ellas hacen",
                "english": "They do/They make"
            },
            {
                "spanish_verb": "hacen",
                "spanish_verb_person": "Ustedes hacen",
                "english": "You all do/You all make (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "hice",
                "spanish_verb_person": "Yo hice",
                "english": "I did/I made"
            },
            {
                "spanish_verb": "hiciste",
                "spanish_verb_person": "T\u00fa hiciste",
                "english": "You did/You made"
            },
            {
                "spanish_verb": "hizo",
                "spanish_verb_person": "\u00c9l hizo",
                "english": "He did/He made"
            },
            {
                "spanish_verb": "hizo",
                "spanish_verb_person": "Ella hizo",
                "english": "She did/She made"
            },
            {
                "spanish_verb": "hizo",
                "spanish_verb_person": "Usted hizo",
                "english": "You did/You made (formal)"
            },
            {
                "spanish_verb": "hicimos",
                "spanish_verb_person": "Nosotros/Nosotras hicimos",
                "english": "We did/We made"
            },
            {
                "spanish_verb": "hicisteis",
                "spanish_verb_person": "Vosotros/Vosotras hicisteis",
                "english": "You all did/You all made"
            },
            {
                "spanish_verb": "hicieron",
                "spanish_verb_person": "Ellos/Ellas hicieron",
                "english": "They did/They made"
            },
            {
                "spanish_verb": "hicieron",
                "spanish_verb_person": "Ustedes hicieron",
                "english": "You all did/You all made (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "hac\u00eda",
                "spanish_verb_person": "Yo hac\u00eda",
                "english": "I was doing/I was making"
            },
            {
                "spanish_verb": "hac\u00edas",
                "spanish_verb_person": "T\u00fa hac\u00edas",
                "english": "You were doing/You was making"
            },
            {
                "spanish_verb": "hac\u00eda",
                "spanish_verb_person": "\u00c9l hac\u00eda",
                "english": "He was doing/He was making"
            },
            {
                "spanish_verb": "hac\u00eda",
                "spanish_verb_person": "Ella hac\u00eda",
                "english": "She was doing/She was making"
            },
            {
                "spanish_verb": "hac\u00eda",
                "spanish_verb_person": "Usted hac\u00eda",
                "english": "You were doing/You were making (formal)"
            },
            {
                "spanish_verb": "hac\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras hac\u00edamos",
                "english": "We were doing/We were making"
            },
            {
                "spanish_verb": "hac\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras hac\u00edais",
                "english": "You all were doing/You all were making"
            },
            {
                "spanish_verb": "hac\u00edan",
                "spanish_verb_person": "Ellos/Ellas hac\u00edan",
                "english": "They were doing/They were making"
            },
            {
                "spanish_verb": "hac\u00edan",
                "spanish_verb_person": "Ustedes hac\u00edan",
                "english": "You all were doing/You all were making (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "har\u00e9",
                "spanish_verb_person": "Yo har\u00e9",
                "english": "I will do/I will make"
            },
            {
                "spanish_verb": "har\u00e1s",
                "spanish_verb_person": "T\u00fa har\u00e1s",
                "english": "You will do/You will make"
            },
            {
                "spanish_verb": "har\u00e1",
                "spanish_verb_person": "\u00c9l har\u00e1",
                "english": "He will do/He will make"
            },
            {
                "spanish_verb": "har\u00e1",
                "spanish_verb_person": "Ella har\u00e1",
                "english": "She will do/She will make"
            },
            {
                "spanish_verb": "har\u00e1",
                "spanish_verb_person": "Usted har\u00e1",
                "english": "You will do/You will make (formal)"
            },
            {
                "spanish_verb": "haremos",
                "spanish_verb_person": "Nosotros/Nosotras haremos",
                "english": "We will do/We will make"
            },
            {
                "spanish_verb": "har\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras har\u00e9is",
                "english": "You all will do/You all will make"
            },
            {
                "spanish_verb": "har\u00e1n",
                "spanish_verb_person": "Ellos/Ellas har\u00e1n",
                "english": "They will do/They will make"
            },
            {
                "spanish_verb": "har\u00e1n",
                "spanish_verb_person": "Ustedes har\u00e1n",
                "english": "You all will do/You all will make (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "har\u00eda",
                "spanish_verb_person": "Yo har\u00eda",
                "english": "I would do/I would make"
            },
            {
                "spanish_verb": "har\u00edas",
                "spanish_verb_person": "T\u00fa har\u00edas",
                "english": "You would do/You would make"
            },
            {
                "spanish_verb": "har\u00eda",
                "spanish_verb_person": "\u00c9l har\u00eda",
                "english": "He would do/He would make"
            },
            {
                "spanish_verb": "har\u00eda",
                "spanish_verb_person": "Ella har\u00eda",
                "english": "She would do/She would make"
            },
            {
                "spanish_verb": "har\u00eda",
                "spanish_verb_person": "Usted har\u00eda",
                "english": "You would do/You would make (formal)"
            },
            {
                "spanish_verb": "har\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras har\u00edamos",
                "english": "We would do/We would make"
            },
            {
                "spanish_verb": "har\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras har\u00edais",
                "english": "You all would do/You all would make"
            },
            {
                "spanish_verb": "har\u00edan",
                "spanish_verb_person": "Ellos/Ellas har\u00edan",
                "english": "They would do/They would make"
            },
            {
                "spanish_verb": "har\u00edan",
                "spanish_verb_person": "Ustedes har\u00edan",
                "english": "You all would do/You all would make (formal)"
            }
        ]
    }
}
export default hacer;