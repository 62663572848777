const ser = {
    "verb": "ser",
    "english": "to be",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "soy",
                "spanish_verb_person": "Yo soy",
                "english": "I am"
            },
            {
                "spanish_verb": "eres",
                "spanish_verb_person": "T\u00fa eres",
                "english": "You are"
            },
            {
                "spanish_verb": "es",
                "spanish_verb_person": "\u00c9l es",
                "english": "He is"
            },
            {
                "spanish_verb": "es",
                "spanish_verb_person": "Ella es",
                "english": "She is"
            },
            {
                "spanish_verb": "es",
                "spanish_verb_person": "Usted es",
                "english": "You are (formal)"
            },
            {
                "spanish_verb": "somos",
                "spanish_verb_person": "Nosotros somos",
                "english": "We are"
            },
            {
                "spanish_verb": "sois",
                "spanish_verb_person": "Vosotros sois",
                "english": "You all are"
            },
            {
                "spanish_verb": "son",
                "spanish_verb_person": "Ellos/Ellas son",
                "english": "They are"
            },
            {
                "spanish_verb": "son",
                "spanish_verb_person": "Ustedes son",
                "english": "You all are (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "fui",
                "spanish_verb_person": "Yo fui",
                "english": "I was"
            },
            {
                "spanish_verb": "fuiste",
                "spanish_verb_person": "T\u00fa fuiste",
                "english": "You were"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "\u00c9l fue",
                "english": "He was"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "Ella fue",
                "english": "She was"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "Usted fue",
                "english": "You were (formal)"
            },
            {
                "spanish_verb": "fuimos",
                "spanish_verb_person": "Nosotros fuimos",
                "english": "We were"
            },
            {
                "spanish_verb": "fuisteis",
                "spanish_verb_person": "Vosotros fuisteis",
                "english": "You all were"
            },
            {
                "spanish_verb": "fueron",
                "spanish_verb_person": "Ellos/Ellas fueron",
                "english": "They were"
            },
            {
                "spanish_verb": "fueron",
                "spanish_verb_person": "Ustedes fueron",
                "english": "You all were (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "era",
                "spanish_verb_person": "Yo era",
                "english": "I was"
            },
            {
                "spanish_verb": "era",
                "spanish_verb_person": "T\u00fa eras",
                "english": "You were"
            },
            {
                "spanish_verb": "era",
                "spanish_verb_person": "\u00c9l era",
                "english": "He was"
            },
            {
                "spanish_verb": "era",
                "spanish_verb_person": "Ella era",
                "english": "She was"
            },
            {
                "spanish_verb": "era",
                "spanish_verb_person": "Usted era",
                "english": "You were (formal)"
            },
            {
                "spanish_verb": "\u00e9ramos",
                "spanish_verb_person": "Nosotros/Nosotras \u00e9ramos",
                "english": "We were"
            },
            {
                "spanish_verb": "erais",
                "spanish_verb_person": "Vosotros/Vosotras erais",
                "english": "You all were"
            },
            {
                "spanish_verb": "eran",
                "spanish_verb_person": "Ellos/Ellas eran",
                "english": "They were"
            },
            {
                "spanish_verb": "eran",
                "spanish_verb_person": "Ustedes eran",
                "english": "You all were (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "ser\u00e9",
                "spanish_verb_person": "Yo ser\u00e9",
                "english": "I will be"
            },
            {
                "spanish_verb": "ser\u00e1s",
                "spanish_verb_person": "T\u00fa ser\u00e1s",
                "english": "You will be"
            },
            {
                "spanish_verb": "ser\u00e1",
                "spanish_verb_person": "\u00c9l ser\u00e1",
                "english": "He will be"
            },
            {
                "spanish_verb": "ser\u00e1",
                "spanish_verb_person": "Ella ser\u00e1",
                "english": "She will be"
            },
            {
                "spanish_verb": "ser\u00e1",
                "spanish_verb_person": "Usted ser\u00e1",
                "english": "You will be (formal)"
            },
            {
                "spanish_verb": "seremos",
                "spanish_verb_person": "Nosotros/Nosotras seremos",
                "english": "We will be"
            },
            {
                "spanish_verb": "seremos",
                "spanish_verb_person": "Vosotros/Vosotras ser\u00e9is",
                "english": "You all will be"
            },
            {
                "spanish_verb": "ser\u00e1n",
                "spanish_verb_person": "Ellos/Ellas ser\u00e1n",
                "english": "They will be"
            },
            {
                "spanish_verb": "ser\u00e1n",
                "spanish_verb_person": "Ustedes ser\u00e1n",
                "english": "You all will be (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "ser\u00eda",
                "spanish_verb_person": "Yo ser\u00eda",
                "english": "I would be"
            },
            {
                "spanish_verb": "ser\u00edas",
                "spanish_verb_person": "T\u00fa ser\u00edas",
                "english": "You would be"
            },
            {
                "spanish_verb": "ser\u00eda",
                "spanish_verb_person": "\u00c9l ser\u00eda",
                "english": "He would be"
            },
            {
                "spanish_verb": "ser\u00edas",
                "spanish_verb_person": "Ella ser\u00eda",
                "english": "She would be"
            },
            {
                "spanish_verb": "ser\u00edas",
                "spanish_verb_person": "Usted ser\u00eda",
                "english": "You would be (formal)"
            },
            {
                "spanish_verb": "ser\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ser\u00edamos",
                "english": "We would be"
            },
            {
                "spanish_verb": "ser\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ser\u00edais",
                "english": "You all would be"
            },
            {
                "spanish_verb": "ser\u00edais",
                "spanish_verb_person": "Ellos/Ellas ser\u00edan",
                "english": "They would be"
            },
            {
                "spanish_verb": "ser\u00edais",
                "spanish_verb_person": "Ustedes ser\u00edan",
                "english": "You all would be (formal)"
            }
        ]
    }
}
export default ser;