import React, { useRef, useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import styles from './VerbDropdown.module.scss';
import { setVerbOption } from '../../redux/verbSlice';
import verbMap from '../../conjugations/verbMap';

const createLabel = spanVerb => `${spanVerb} - (${verbMap.get(spanVerb)["english"]})`;

const options = [...verbMap.keys()];
const initialInputValue = createLabel(options[0]);

function VerbDropdown(props) {
  const inputElement = useRef();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const handleInputKeyUp = (event) => {
    const verb = event.target.value;
    if (event.key === 'Enter' && options.includes(verb)) {
      handleButtonClick(event.target.value)
      return;
    }
    setInputValue(event.target.value);
  }

  const handleButtonClick = (spanishVerb) => {
    setInputValue(spanishVerb);
    inputElement.current.value = spanishVerb;
    dispatch(setVerbOption(spanishVerb))
  }

  const handleEraseButtonClicked = () => {
    inputElement.current.value = "";
    setInputValue("");
  }

  useEffect(() => {
    inputElement.current.value = initialInputValue;
  }, []);
  return ( 
    <div className={styles.dropdown}>
      <div id={styles["dropdown-container"]}>
        <input 
          className={styles["search-input"]} 
          type="text" 
          onKeyUp={handleInputKeyUp}
          onFocus={handleEraseButtonClicked}
          ref={inputElement}
        /> 
        <div className={styles["select-menu"]}>
          { options
              // filter the menu based on input text
              .filter(verb => verb.includes(inputValue.toLowerCase()))
              .map(spanishVerb => (
                <div 
                  className={styles["select-item"]} 
                  key={spanishVerb}
                  onClick={() => handleButtonClick(spanishVerb)}
                >
                  {createLabel(spanishVerb)}
                </div>
              ))
          }
        </div>
        <div className={styles["magnifying-glass-icon"]}>
          🔎
        </div>
        <div 
          className={styles["erase-button"]}
          onClick={() => handleEraseButtonClicked()}
        >
          X
        </div>
      </div> 
    </div>
  );
}

export default VerbDropdown;
