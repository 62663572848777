const dar = {
    "verb": "dar",
    "english": "to give",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "doy",
                "spanish_verb_person": "Yo doy",
                "english": "I give"
            },
            {
                "spanish_verb": "das",
                "spanish_verb_person": "T\u00fa das",
                "english": "You give"
            },
            {
                "spanish_verb": "da",
                "spanish_verb_person": "\u00c9l da",
                "english": "He gives"
            },
            {
                "spanish_verb": "da",
                "spanish_verb_person": "Ella da",
                "english": "She gives"
            },
            {
                "spanish_verb": "da",
                "spanish_verb_person": "Usted da",
                "english": "You give (formal)"
            },
            {
                "spanish_verb": "damos",
                "spanish_verb_person": "Nosotros/Nosotras damos",
                "english": "We give"
            },
            {
                "spanish_verb": "dais",
                "spanish_verb_person": "Vosotros/Vosotras dais",
                "english": "You all give"
            },
            {
                "spanish_verb": "dan",
                "spanish_verb_person": "Ellos/Ellas dan",
                "english": "They give"
            },
            {
                "spanish_verb": "dan",
                "spanish_verb_person": "Ustedes dan",
                "english": "You all give (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "di",
                "spanish_verb_person": "Yo di",
                "english": "I gave"
            },
            {
                "spanish_verb": "diste",
                "spanish_verb_person": "T\u00fa diste",
                "english": "You gave"
            },
            {
                "spanish_verb": "dio",
                "spanish_verb_person": "\u00c9l dio",
                "english": "He gave"
            },
            {
                "spanish_verb": "dio",
                "spanish_verb_person": "Ella dio",
                "english": "She gave"
            },
            {
                "spanish_verb": "dio",
                "spanish_verb_person": "Usted dio",
                "english": "You gave (formal)"
            },
            {
                "spanish_verb": "dimos",
                "spanish_verb_person": "Nosotros/Nosotras dimos",
                "english": "We gave"
            },
            {
                "spanish_verb": "disteis",
                "spanish_verb_person": "Vosotros/Vosotras disteis",
                "english": "You all gave"
            },
            {
                "spanish_verb": "dieron",
                "spanish_verb_person": "Ellos/Ellas dieron",
                "english": "They gave"
            },
            {
                "spanish_verb": "dieron",
                "spanish_verb_person": "Ustedes dieron",
                "english": "You all gave (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "daba",
                "spanish_verb_person": "Yo daba",
                "english": "I gave/I used to give"
            },
            {
                "spanish_verb": "dabas",
                "spanish_verb_person": "T\u00fa dabas",
                "english": "You gave/You used to give"
            },
            {
                "spanish_verb": "daba",
                "spanish_verb_person": "\u00c9l daba",
                "english": "He gave"
            },
            {
                "spanish_verb": "daba",
                "spanish_verb_person": "Ella daba",
                "english": "She gave"
            },
            {
                "spanish_verb": "daba",
                "spanish_verb_person": "Usted daba",
                "english": "You gave (formal)"
            },
            {
                "spanish_verb": "d\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras d\u00e1bamos",
                "english": "We gave/We used to give"
            },
            {
                "spanish_verb": "dabais",
                "spanish_verb_person": "Vosotros/Vosotras dabais",
                "english": "You all gave/You all used to give"
            },
            {
                "spanish_verb": "daban",
                "spanish_verb_person": "Ellos/Ellas daban",
                "english": "They gave"
            },
            {
                "spanish_verb": "daban",
                "spanish_verb_person": "Ustedes daban",
                "english": "You all gave (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "dar\u00e9",
                "spanish_verb_person": "Yo dar\u00e9",
                "english": "I will give"
            },
            {
                "spanish_verb": "dar\u00e1s",
                "spanish_verb_person": "T\u00fa dar\u00e1s",
                "english": "You will give"
            },
            {
                "spanish_verb": "dar\u00e1",
                "spanish_verb_person": "\u00c9l dar\u00e1",
                "english": "He will give"
            },
            {
                "spanish_verb": "dar\u00e1",
                "spanish_verb_person": "Ella dar\u00e1",
                "english": "She will give"
            },
            {
                "spanish_verb": "dar\u00e1",
                "spanish_verb_person": "Usted dar\u00e1",
                "english": "You will give (formal)"
            },
            {
                "spanish_verb": "daremos",
                "spanish_verb_person": "Nosotros/Nosotras daremos",
                "english": "We will give"
            },
            {
                "spanish_verb": "dar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras dar\u00e9is",
                "english": "You all will give"
            },
            {
                "spanish_verb": "dar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas dar\u00e1n",
                "english": "They will give"
            },
            {
                "spanish_verb": "dar\u00e1n",
                "spanish_verb_person": "Ustedes dar\u00e1n",
                "english": "You all will give (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "dar\u00eda",
                "spanish_verb_person": "Yo dar\u00eda",
                "english": "I would give"
            },
            {
                "spanish_verb": "dar\u00edas",
                "spanish_verb_person": "T\u00fa dar\u00edas",
                "english": "You would give"
            },
            {
                "spanish_verb": "dar\u00eda",
                "spanish_verb_person": "\u00c9l dar\u00eda",
                "english": "He would give"
            },
            {
                "spanish_verb": "dar\u00eda",
                "spanish_verb_person": "Ella dar\u00eda",
                "english": "She would give"
            },
            {
                "spanish_verb": "dar\u00eda",
                "spanish_verb_person": "Usted dar\u00eda",
                "english": "You would give (formal)"
            },
            {
                "spanish_verb": "dar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras dar\u00edamos",
                "english": "We would give"
            },
            {
                "spanish_verb": "dar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras dar\u00edais",
                "english": "You all would give"
            },
            {
                "spanish_verb": "dar\u00edan",
                "spanish_verb_person": "Ellos/Ellas dar\u00edan",
                "english": "They would give"
            },
            {
                "spanish_verb": "dar\u00edan",
                "spanish_verb_person": "Ustedes dar\u00edan",
                "english": "You all would give (formal)"
            }
        ]
    }
}
export default dar;