const pasar = {
    "verb": "pasar",
    "english": "to pass",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "paso",
                "spanish_verb_person": "Yo paso",
                "english": "I pass"
            },
            {
                "spanish_verb": "pasas",
                "spanish_verb_person": "T\u00fa pasas",
                "english": "You pass"
            },
            {
                "spanish_verb": "pasa",
                "spanish_verb_person": "\u00c9l pasa",
                "english": "He passes"
            },
            {
                "spanish_verb": "pasa",
                "spanish_verb_person": "Ella pasa",
                "english": "She passes"
            },
            {
                "spanish_verb": "pasa",
                "spanish_verb_person": "Usted pasa",
                "english": "You pass (formal)"
            },
            {
                "spanish_verb": "pasamos",
                "spanish_verb_person": "Nosotros/Nosotras pasamos",
                "english": "We pass"
            },
            {
                "spanish_verb": "pas\u00e1is",
                "spanish_verb_person": "Vosotros/Vosotras pas\u00e1is",
                "english": "You all pass"
            },
            {
                "spanish_verb": "pasan",
                "spanish_verb_person": "Ellos/Ellas pasan",
                "english": "They pass"
            },
            {
                "spanish_verb": "pasan",
                "spanish_verb_person": "Ustedes pasan",
                "english": "You all pass (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "pas\u00e9",
                "spanish_verb_person": "Yo pas\u00e9",
                "english": "I passed"
            },
            {
                "spanish_verb": "pasaste",
                "spanish_verb_person": "T\u00fa pasaste",
                "english": "You passed"
            },
            {
                "spanish_verb": "pas\u00f3",
                "spanish_verb_person": "\u00c9l pas\u00f3",
                "english": "He passed"
            },
            {
                "spanish_verb": "pas\u00f3",
                "spanish_verb_person": "Ella pas\u00f3",
                "english": "She passed"
            },
            {
                "spanish_verb": "pas\u00f3",
                "spanish_verb_person": "Usted pas\u00f3",
                "english": "You passed (formal)"
            },
            {
                "spanish_verb": "pasamos",
                "spanish_verb_person": "Nosotros/Nosotras pasamos",
                "english": "We passed"
            },
            {
                "spanish_verb": "pasasteis",
                "spanish_verb_person": "Vosotros/Vosotras pasasteis",
                "english": "You all passed"
            },
            {
                "spanish_verb": "pasaron",
                "spanish_verb_person": "Ellos/Ellas pasaron",
                "english": "They passed"
            },
            {
                "spanish_verb": "pasaron",
                "spanish_verb_person": "Ustedes pasaron",
                "english": "You all passed (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "pasaba",
                "spanish_verb_person": "Yo pasaba",
                "english": "I passed/I used to pass"
            },
            {
                "spanish_verb": "pasabas",
                "spanish_verb_person": "T\u00fa pasabas",
                "english": "You passed/You used to pass"
            },
            {
                "spanish_verb": "pasaba",
                "spanish_verb_person": "\u00c9l pasaba",
                "english": "He passed/He used to pass"
            },
            {
                "spanish_verb": "pasaba",
                "spanish_verb_person": "Ella pasaba",
                "english": "She passed/She used to pass"
            },
            {
                "spanish_verb": "pasaba",
                "spanish_verb_person": "Usted pasaba",
                "english": "You passed/You used to pass"
            },
            {
                "spanish_verb": "pas\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras pas\u00e1bamos",
                "english": "We passed/We used to pass"
            },
            {
                "spanish_verb": "pasabais",
                "spanish_verb_person": "Vosotros/Vosotras pasabais",
                "english": "You all passed/You all used to pass"
            },
            {
                "spanish_verb": "pasaban",
                "spanish_verb_person": "Ellos/Ellas pasaban",
                "english": "They passed/They used to pass"
            },
            {
                "spanish_verb": "pasaban",
                "spanish_verb_person": "Ustedes pasaban",
                "english": "You all passed/You all used to pass"
            }
        ],
        "future": [
            {
                "spanish_verb": "pasar\u00e9",
                "spanish_verb_person": "Yo pasar\u00e9",
                "english": "I will pass"
            },
            {
                "spanish_verb": "pasar\u00e1s",
                "spanish_verb_person": "T\u00fa pasar\u00e1s",
                "english": "You will pass"
            },
            {
                "spanish_verb": "pasar\u00e1",
                "spanish_verb_person": "\u00c9l pasar\u00e1",
                "english": "He will pass"
            },
            {
                "spanish_verb": "pasar\u00e1",
                "spanish_verb_person": "Ella pasar\u00e1",
                "english": "She will pass"
            },
            {
                "spanish_verb": "pasar\u00e1",
                "spanish_verb_person": "Usted pasar\u00e1",
                "english": "You will pass (formal)"
            },
            {
                "spanish_verb": "pasaremos",
                "spanish_verb_person": "Nosotros/Nosotras pasaremos",
                "english": "We will pass"
            },
            {
                "spanish_verb": "pasar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras pasar\u00e9is",
                "english": "You all will pass"
            },
            {
                "spanish_verb": "pasar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas pasar\u00e1n",
                "english": "They will pass"
            },
            {
                "spanish_verb": "pasar\u00e1n",
                "spanish_verb_person": "Ustedes pasar\u00e1n",
                "english": "You all will pass (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "pasar\u00eda",
                "spanish_verb_person": "Yo pasar\u00eda",
                "english": "I would pass"
            },
            {
                "spanish_verb": "pasar\u00edas",
                "spanish_verb_person": "T\u00fa pasar\u00edas",
                "english": "You would pass"
            },
            {
                "spanish_verb": "pasar\u00eda",
                "spanish_verb_person": "\u00c9l pasar\u00eda",
                "english": "He would pass"
            },
            {
                "spanish_verb": "pasar\u00eda",
                "spanish_verb_person": "Ella pasar\u00eda",
                "english": "She would pass"
            },
            {
                "spanish_verb": "pasar\u00eda",
                "spanish_verb_person": "Usted pasar\u00eda",
                "english": "You would pass (formal)"
            },
            {
                "spanish_verb": "pasar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras pasar\u00edamos",
                "english": "We would pass"
            },
            {
                "spanish_verb": "pasar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras pasar\u00edais",
                "english": "You all would pass"
            },
            {
                "spanish_verb": "pasar\u00edan",
                "spanish_verb_person": "Ellos/Ellas pasar\u00edan",
                "english": "They would pass"
            },
            {
                "spanish_verb": "pasar\u00edan",
                "spanish_verb_person": "Ustedes pasar\u00edan",
                "english": "You all would pass (formal)"
            }
        ]
    }
}
export default pasar;