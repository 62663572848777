const decir = {
    "verb": "decir",
    "english": "to say",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "digo",
                "spanish_verb_person": "Yo digo",
                "english": "I say"
            },
            {
                "spanish_verb": "dices",
                "spanish_verb_person": "T\u00fa dices",
                "english": "You say"
            },
            {
                "spanish_verb": "dice",
                "spanish_verb_person": "\u00c9l dice",
                "english": "He says"
            },
            {
                "spanish_verb": "dice",
                "spanish_verb_person": "Ella dice",
                "english": "She says"
            },
            {
                "spanish_verb": "dice",
                "spanish_verb_person": "Usted dice",
                "english": "You say (formal)"
            },
            {
                "spanish_verb": "decimos",
                "spanish_verb_person": "Nosotros/Nosotras decimos",
                "english": "We say"
            },
            {
                "spanish_verb": "dec\u00eds",
                "spanish_verb_person": "Vosotros/Vosotras dec\u00eds",
                "english": "You all say"
            },
            {
                "spanish_verb": "dicen",
                "spanish_verb_person": "Ellos/Ellas dicen",
                "english": "They say"
            },
            {
                "spanish_verb": "dicen",
                "spanish_verb_person": "Ustedes dicen",
                "english": "You all say (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "dije",
                "spanish_verb_person": "Yo dije",
                "english": "I said"
            },
            {
                "spanish_verb": "dijiste",
                "spanish_verb_person": "T\u00fa dijiste",
                "english": "You said"
            },
            {
                "spanish_verb": "dijo",
                "spanish_verb_person": "\u00c9l dijo",
                "english": "He said"
            },
            {
                "spanish_verb": "dijo",
                "spanish_verb_person": "Ella dijo",
                "english": "She said"
            },
            {
                "spanish_verb": "dijo",
                "spanish_verb_person": "Usted dijo",
                "english": "You said (formal)"
            },
            {
                "spanish_verb": "dijimos",
                "spanish_verb_person": "Nosotros/Nosotras dijimos",
                "english": "We said"
            },
            {
                "spanish_verb": "dijisteis",
                "spanish_verb_person": "Vosotros/Vosotras dijisteis",
                "english": "You all said"
            },
            {
                "spanish_verb": "dijeron",
                "spanish_verb_person": "Ellos/Ellas dijeron",
                "english": "They said"
            },
            {
                "spanish_verb": "dijeron",
                "spanish_verb_person": "Ustedes dijeron",
                "english": "You all said (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "dec\u00eda",
                "spanish_verb_person": "Yo dec\u00eda",
                "english": "I was saying/I used to say"
            },
            {
                "spanish_verb": "dec\u00edas",
                "spanish_verb_person": "T\u00fa dec\u00edas",
                "english": "You were saying/You used to say"
            },
            {
                "spanish_verb": "dec\u00eda",
                "spanish_verb_person": "\u00c9l dec\u00eda",
                "english": "He was saying/He used to say"
            },
            {
                "spanish_verb": "dec\u00eda",
                "spanish_verb_person": "Ella dec\u00eda",
                "english": "She was saying/She used to say"
            },
            {
                "spanish_verb": "dec\u00eda",
                "spanish_verb_person": "Usted dec\u00eda",
                "english": "You were saying/You used to say (formal)"
            },
            {
                "spanish_verb": "dec\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras dec\u00edamos",
                "english": "We were saying/We used to say"
            },
            {
                "spanish_verb": "dec\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras dec\u00edais",
                "english": "You all were saying/You all used to say"
            },
            {
                "spanish_verb": "dec\u00edan",
                "spanish_verb_person": "Ellos/Ellas dec\u00edan",
                "english": "They were saying/They used to say"
            },
            {
                "spanish_verb": "dec\u00edan",
                "spanish_verb_person": "Ustedes dec\u00edan",
                "english": "You all were saying/You all used to say"
            }
        ],
        "future": [
            {
                "spanish_verb": "dir\u00e9",
                "spanish_verb_person": "Yo dir\u00e9",
                "english": "I will say"
            },
            {
                "spanish_verb": "dir\u00e1s",
                "spanish_verb_person": "T\u00fa dir\u00e1s",
                "english": "You will say"
            },
            {
                "spanish_verb": "dir\u00e1",
                "spanish_verb_person": "\u00c9l dir\u00e1",
                "english": "He will say"
            },
            {
                "spanish_verb": "dir\u00e1",
                "spanish_verb_person": "Ella dir\u00e1",
                "english": "She will say"
            },
            {
                "spanish_verb": "dir\u00e1",
                "spanish_verb_person": "Usted dir\u00e1",
                "english": "You will say(formal)"
            },
            {
                "spanish_verb": "diremos",
                "spanish_verb_person": "Nosotros/Nosotras diremos",
                "english": "We will say"
            },
            {
                "spanish_verb": "dir\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras dir\u00e9is",
                "english": "You all will say"
            },
            {
                "spanish_verb": "dir\u00e1n",
                "spanish_verb_person": "Ellos/Ellas dir\u00e1n",
                "english": "They will say"
            },
            {
                "spanish_verb": "dir\u00e1n",
                "spanish_verb_person": "Ustedes dir\u00e1n",
                "english": "You all will say (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "dir\u00eda",
                "spanish_verb_person": "Yo dir\u00eda",
                "english": "I would say"
            },
            {
                "spanish_verb": "dir\u00edas",
                "spanish_verb_person": "T\u00fa dir\u00edas",
                "english": "You would say"
            },
            {
                "spanish_verb": "dir\u00eda",
                "spanish_verb_person": "\u00c9l dir\u00eda",
                "english": "He would say"
            },
            {
                "spanish_verb": "dir\u00eda",
                "spanish_verb_person": "Ella dir\u00eda",
                "english": "She would say"
            },
            {
                "spanish_verb": "dir\u00eda",
                "spanish_verb_person": "Usted dir\u00eda",
                "english": "You would say (formal)"
            },
            {
                "spanish_verb": "dir\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras dir\u00edamos",
                "english": "We would say"
            },
            {
                "spanish_verb": "dir\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras dir\u00edais",
                "english": "You all would say"
            },
            {
                "spanish_verb": "dir\u00edan",
                "spanish_verb_person": "Ellos/Ellas dir\u00edan",
                "english": "They would say"
            },
            {
                "spanish_verb": "dir\u00edan",
                "spanish_verb_person": "Ustedes dir\u00edan",
                "english": "You all would say (formal)"
            }
        ]
    }
}
export default decir;