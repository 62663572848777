import { createSlice } from '@reduxjs/toolkit';

const selectedVerb = "ser";

const tenses = [
  "present",
  "preterite",
  "imperfect",
  "future",
  "conditional"
];

const tenseControls = tenses.map(tense => ({ id: tense, label: tense, checked: false }));
tenseControls[0]["checked"] = true;

const otherControls = [
  { id: "shuffle", label: "shuffle", checked: false },
  { id: "englishInFront", label: "English In Front", checked: false }
];

const initialState = {
  selectedVerb,
  tenseControls,
  otherControls 
};

const controlSlice = createSlice({
  name: 'control',
  initialState: initialState,
  reducers: {
    toggleTenseControl: (state, action) => {
      const { payload } = action;
      const updatedTenseControls = state.tenseControls.map(control =>
        control.id === payload ? { ...control, checked: !control.checked } : control
      );
      return { ...state, tenseControls: updatedTenseControls };
    },
    toggleOtherControl : (state, action) => {
      const { payload } = action;
      const updatedOtherControls = state.otherControls.map(control =>
        control.id === payload ? { ...control, checked: !control.checked } : control
      );  
      return { ...state, otherControls: updatedOtherControls };
    }
  },
});

export const { toggleTenseControl, toggleOtherControl } = controlSlice.actions;
export default controlSlice.reducer;