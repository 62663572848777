const ver = {
    "verb": "ver",
    "english": "to see",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "veo",
                "spanish_verb_person": "Yo veo",
                "english": "I see"
            },
            {
                "spanish_verb": "ves",
                "spanish_verb_person": "T\u00fa ves",
                "english": "You see"
            },
            {
                "spanish_verb": "ve",
                "spanish_verb_person": "\u00c9l ve",
                "english": "He sees"
            },
            {
                "spanish_verb": "ve",
                "spanish_verb_person": "Ella ve",
                "english": "She sees"
            },
            {
                "spanish_verb": "ve",
                "spanish_verb_person": "Usted ve",
                "english": "You see (formal)"
            },
            {
                "spanish_verb": "vemos",
                "spanish_verb_person": "Nosotros/Nosotras vemos",
                "english": "We see"
            },
            {
                "spanish_verb": "veis",
                "spanish_verb_person": "Vosotros/Vosotras veis",
                "english": "You all see"
            },
            {
                "spanish_verb": "ven",
                "spanish_verb_person": "Ellos/Ellas ven",
                "english": "They see"
            },
            {
                "spanish_verb": "ven",
                "spanish_verb_person": "Ustedes ven",
                "english": "You all see (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "vi",
                "spanish_verb_person": "Yo vi",
                "english": "I saw"
            },
            {
                "spanish_verb": "viste",
                "spanish_verb_person": "T\u00fa viste",
                "english": "You saw"
            },
            {
                "spanish_verb": "vio",
                "spanish_verb_person": "\u00c9l vio",
                "english": "He saw"
            },
            {
                "spanish_verb": "vio",
                "spanish_verb_person": "Ella vio",
                "english": "She saw"
            },
            {
                "spanish_verb": "vio",
                "spanish_verb_person": "Usted vio",
                "english": "You saw (formal)"
            },
            {
                "spanish_verb": "vimos",
                "spanish_verb_person": "Nosotros/Nosotras vimos",
                "english": "We saw"
            },
            {
                "spanish_verb": "visteis",
                "spanish_verb_person": "Vosotros/Vosotras visteis",
                "english": "You all saw"
            },
            {
                "spanish_verb": "vieron",
                "spanish_verb_person": "Ellos/Ellas vieron",
                "english": "They saw"
            },
            {
                "spanish_verb": "vieron",
                "spanish_verb_person": "Ustedes vieron",
                "english": "You all saw (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "ve\u00eda",
                "spanish_verb_person": "Yo ve\u00eda",
                "english": "I was seeing/I used to see"
            },
            {
                "spanish_verb": "ve\u00edas",
                "spanish_verb_person": "T\u00fa ve\u00edas",
                "english": "You were seeing/You used to see"
            },
            {
                "spanish_verb": "ve\u00eda",
                "spanish_verb_person": "\u00c9l ve\u00eda",
                "english": "He was seeing/He used to see"
            },
            {
                "spanish_verb": "ve\u00eda",
                "spanish_verb_person": "Ella ve\u00eda",
                "english": "She was seeing/She used to see"
            },
            {
                "spanish_verb": "ve\u00eda",
                "spanish_verb_person": "Usted ve\u00eda",
                "english": "You were seeing/You used to see (formal)"
            },
            {
                "spanish_verb": "ve\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ve\u00edamos",
                "english": "We were seeing/We used to see"
            },
            {
                "spanish_verb": "ve\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ve\u00edais",
                "english": "You all were seeing/You all used to see"
            },
            {
                "spanish_verb": "ve\u00edan",
                "spanish_verb_person": "Ellos/Ellas ve\u00edan",
                "english": "They were seeing/They used to see"
            },
            {
                "spanish_verb": "ve\u00edan",
                "spanish_verb_person": "Ustedes ve\u00edan",
                "english": "You all were seeing/You all used to see (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "ver\u00e9",
                "spanish_verb_person": "Yo ver\u00e9",
                "english": "I will see"
            },
            {
                "spanish_verb": "ver\u00e1s",
                "spanish_verb_person": "T\u00fa ver\u00e1s",
                "english": "You will see"
            },
            {
                "spanish_verb": "ver\u00e1",
                "spanish_verb_person": "\u00c9l ver\u00e1",
                "english": "He will see"
            },
            {
                "spanish_verb": "ver\u00e1",
                "spanish_verb_person": "Ella ver\u00e1",
                "english": "She will see"
            },
            {
                "spanish_verb": "ver\u00e1",
                "spanish_verb_person": "Usted ver\u00e1",
                "english": "You will see (formal)"
            },
            {
                "spanish_verb": "veremos",
                "spanish_verb_person": "Nosotros/Nosotras veremos",
                "english": "We will see"
            },
            {
                "spanish_verb": "ver\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras ver\u00e9is",
                "english": "You all will see"
            },
            {
                "spanish_verb": "ver\u00e1n",
                "spanish_verb_person": "Ellos/Ellas ver\u00e1n",
                "english": "They will see"
            },
            {
                "spanish_verb": "ver\u00e1n",
                "spanish_verb_person": "Ustedes ver\u00e1n",
                "english": "You all will see (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "ver\u00eda",
                "spanish_verb_person": "Yo ver\u00eda",
                "english": "I would see"
            },
            {
                "spanish_verb": "ver\u00edas",
                "spanish_verb_person": "T\u00fa ver\u00edas",
                "english": "You would see"
            },
            {
                "spanish_verb": "ver\u00eda",
                "spanish_verb_person": "\u00c9l ver\u00eda",
                "english": "He would see"
            },
            {
                "spanish_verb": "ver\u00eda",
                "spanish_verb_person": "Ella ver\u00eda",
                "english": "She would see"
            },
            {
                "spanish_verb": "ver\u00eda",
                "spanish_verb_person": "Usted ver\u00eda",
                "english": "You would see (formal)"
            },
            {
                "spanish_verb": "ver\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ver\u00edamos",
                "english": "We would see"
            },
            {
                "spanish_verb": "ver\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ver\u00edais",
                "english": "You all would see"
            },
            {
                "spanish_verb": "ver\u00edan",
                "spanish_verb_person": "Ellos/Ellas ver\u00edan",
                "english": "They would see"
            },
            {
                "spanish_verb": "ver\u00edan",
                "spanish_verb_person": "Ustedes ver\u00edan",
                "english": "You all would see (formal)"
            }
        ]
    }
}
export default ver;