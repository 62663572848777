const pensar = {
    "verb": "pensar",
    "english": "to think",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "pienso",
                "spanish_verb_person": "Yo pienso",
                "english": "I think"
            },
            {
                "spanish_verb": "piensas",
                "spanish_verb_person": "T\u00fa piensas",
                "english": "You think"
            },
            {
                "spanish_verb": "piensa",
                "spanish_verb_person": "\u00c9l piensa",
                "english": "He thinks"
            },
            {
                "spanish_verb": "piensa",
                "spanish_verb_person": "Ella piensa",
                "english": "She thinks"
            },
            {
                "spanish_verb": "piensa",
                "spanish_verb_person": "Usted piensa",
                "english": "You think (formal)"
            },
            {
                "spanish_verb": "pensamos",
                "spanish_verb_person": "Nosotros/Nosotras pensamos",
                "english": "We think"
            },
            {
                "spanish_verb": "pens\u00e1is",
                "spanish_verb_person": "Vosotros/Vosotras pens\u00e1is",
                "english": "You all think"
            },
            {
                "spanish_verb": "piensan",
                "spanish_verb_person": "Ellos/Ellas piensan",
                "english": "They think"
            },
            {
                "spanish_verb": "piensan",
                "spanish_verb_person": "Ustedes piensan",
                "english": "You all think (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "pens\u00e9",
                "spanish_verb_person": "Yo pens\u00e9",
                "english": "I thought"
            },
            {
                "spanish_verb": "pensaste",
                "spanish_verb_person": "T\u00fa pensaste",
                "english": "You thought"
            },
            {
                "spanish_verb": "pens\u00f3",
                "spanish_verb_person": "\u00c9l pens\u00f3",
                "english": "He thought"
            },
            {
                "spanish_verb": "pens\u00f3",
                "spanish_verb_person": "Ella pens\u00f3",
                "english": "She thought"
            },
            {
                "spanish_verb": "pens\u00f3",
                "spanish_verb_person": "Usted pens\u00f3",
                "english": "You thought (formal)"
            },
            {
                "spanish_verb": "pensamos",
                "spanish_verb_person": "Nosotros/Nosotras pensamos",
                "english": "We thought"
            },
            {
                "spanish_verb": "pensasteis",
                "spanish_verb_person": "Vosotros/Vosotras pensasteis",
                "english": "You all thought"
            },
            {
                "spanish_verb": "pensaron",
                "spanish_verb_person": "Ellos/Ellas pensaron",
                "english": "They thought"
            },
            {
                "spanish_verb": "pensaron",
                "spanish_verb_person": "Ustedes pensaron",
                "english": "You all thought (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "pensaba",
                "spanish_verb_person": "Yo pensaba",
                "english": "I thought/I used to think"
            },
            {
                "spanish_verb": "pensabas",
                "spanish_verb_person": "T\u00fa pensabas",
                "english": "You thought/You used to think"
            },
            {
                "spanish_verb": "pensaba",
                "spanish_verb_person": "\u00c9l pensaba",
                "english": "He thought/He used to think"
            },
            {
                "spanish_verb": "pensaba",
                "spanish_verb_person": "Ella pensaba",
                "english": "She thought/She used to think"
            },
            {
                "spanish_verb": "pensaba",
                "spanish_verb_person": "Usted pensaba",
                "english": "You thought/You used to think (formal)"
            },
            {
                "spanish_verb": "pens\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras pens\u00e1bamos",
                "english": "We thought/We used to think"
            },
            {
                "spanish_verb": "pensabais",
                "spanish_verb_person": "Vosotros/Vosotras pensabais",
                "english": "You all thought/You all used to think"
            },
            {
                "spanish_verb": "pensaban",
                "spanish_verb_person": "Ellos/Ellas pensaban",
                "english": "They thought/They used to think"
            },
            {
                "spanish_verb": "pensaban",
                "spanish_verb_person": "Ustedes pensaban",
                "english": "You all thought/You all used to think (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "pensar\u00e9",
                "spanish_verb_person": "Yo pensar\u00e9",
                "english": "I will think"
            },
            {
                "spanish_verb": "pensar\u00e1s",
                "spanish_verb_person": "T\u00fa pensar\u00e1s",
                "english": "You will think"
            },
            {
                "spanish_verb": "pensar\u00e1",
                "spanish_verb_person": "\u00c9l pensar\u00e1",
                "english": "He will think"
            },
            {
                "spanish_verb": "pensar\u00e1",
                "spanish_verb_person": "Ella pensar\u00e1",
                "english": "She will think"
            },
            {
                "spanish_verb": "pensar\u00e1",
                "spanish_verb_person": "Usted pensar\u00e1",
                "english": "You will think (formal)"
            },
            {
                "spanish_verb": "pensaremos",
                "spanish_verb_person": "Nosotros/Nosotras pensaremos",
                "english": "We will think"
            },
            {
                "spanish_verb": "pensar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras pensar\u00e9is",
                "english": "You all will think"
            },
            {
                "spanish_verb": "pensar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas pensar\u00e1n",
                "english": "They will think"
            },
            {
                "spanish_verb": "pensar\u00e1n",
                "spanish_verb_person": "Ustedes pensar\u00e1n",
                "english": "You all will think (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "pensar\u00eda",
                "spanish_verb_person": "Yo pensar\u00eda",
                "english": "I would think"
            },
            {
                "spanish_verb": "pensar\u00edas",
                "spanish_verb_person": "T\u00fa pensar\u00edas",
                "english": "You would think"
            },
            {
                "spanish_verb": "pensar\u00eda",
                "spanish_verb_person": "\u00c9l pensar\u00eda",
                "english": "He would think"
            },
            {
                "spanish_verb": "pensar\u00eda",
                "spanish_verb_person": "Ella pensar\u00eda",
                "english": "She would think"
            },
            {
                "spanish_verb": "pensar\u00eda",
                "spanish_verb_person": "Usted pensar\u00eda",
                "english": "You would think (formal)"
            },
            {
                "spanish_verb": "pensar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras pensar\u00edamos",
                "english": "We would think"
            },
            {
                "spanish_verb": "pensar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras pensar\u00edais",
                "english": "You all would think"
            },
            {
                "spanish_verb": "pensar\u00edan",
                "spanish_verb_person": "Ellos/Ellas pensar\u00edan",
                "english": "They would think"
            },
            {
                "spanish_verb": "pensar\u00edan",
                "spanish_verb_person": "Ustedes pensar\u00edan",
                "english": "You all would think (formal)"
            }
        ]
    }
}
export default pensar;