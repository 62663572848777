import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedVerb: "ser"
};

const verbSlice = createSlice({
  name: 'verb',
  initialState: initialState,
  reducers: {
    setVerbOption: (state, action) => {
      state.selectedVerb = action.payload;
    }
  },
});

export const { setVerbOption } = verbSlice.actions;
export default verbSlice.reducer;
