const venir = {
    "verb": "venir",
    "english": "to come",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "vengo",
                "spanish_verb_person": "Yo vengo",
                "english": "I come"
            },
            {
                "spanish_verb": "vienes",
                "spanish_verb_person": "T\u00fa vienes",
                "english": "You come"
            },
            {
                "spanish_verb": "viene",
                "spanish_verb_person": "\u00c9l viene",
                "english": "He comes"
            },
            {
                "spanish_verb": "viene",
                "spanish_verb_person": "Ella viene",
                "english": "She comes"
            },
            {
                "spanish_verb": "viene",
                "spanish_verb_person": "Usted viene",
                "english": "You come (formal)"
            },
            {
                "spanish_verb": "venimos",
                "spanish_verb_person": "Nosotros/Nosotras venimos",
                "english": "We come"
            },
            {
                "spanish_verb": "ven\u00eds",
                "spanish_verb_person": "Vosotros/Vosotras ven\u00eds",
                "english": "You all come"
            },
            {
                "spanish_verb": "vienen",
                "spanish_verb_person": "Ellos/Ellas vienen",
                "english": "They come"
            },
            {
                "spanish_verb": "vienen",
                "spanish_verb_person": "Ustedes vienen",
                "english": "You all come (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "vine",
                "spanish_verb_person": "Yo vine",
                "english": "I came"
            },
            {
                "spanish_verb": "viniste",
                "spanish_verb_person": "T\u00fa viniste",
                "english": "You came"
            },
            {
                "spanish_verb": "vino",
                "spanish_verb_person": "\u00c9l vino",
                "english": "He came"
            },
            {
                "spanish_verb": "vino",
                "spanish_verb_person": "Ella vino",
                "english": "She came"
            },
            {
                "spanish_verb": "vino",
                "spanish_verb_person": "Usted vino",
                "english": "You came (formal)"
            },
            {
                "spanish_verb": "vinimos",
                "spanish_verb_person": "Nosotros/Nosotras vinimos",
                "english": "We came"
            },
            {
                "spanish_verb": "vinisteis",
                "spanish_verb_person": "Vosotros/Vosotras vinisteis",
                "english": "You all came"
            },
            {
                "spanish_verb": "vinieron",
                "spanish_verb_person": "Ellos/Ellas vinieron",
                "english": "They came"
            },
            {
                "spanish_verb": "vinieron",
                "spanish_verb_person": "Ustedes vinieron",
                "english": "You all came (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "ven\u00eda",
                "spanish_verb_person": "Yo ven\u00eda",
                "english": "I came/I used to come"
            },
            {
                "spanish_verb": "ven\u00edas",
                "spanish_verb_person": "T\u00fa ven\u00edas",
                "english": "You came/You used to come"
            },
            {
                "spanish_verb": "ven\u00eda",
                "spanish_verb_person": "\u00c9l ven\u00eda",
                "english": "He came/He used to come"
            },
            {
                "spanish_verb": "ven\u00eda",
                "spanish_verb_person": "Ella ven\u00eda",
                "english": "She came/She used to come"
            },
            {
                "spanish_verb": "ven\u00eda",
                "spanish_verb_person": "Usted ven\u00eda",
                "english": "You came/You used to come (formal)"
            },
            {
                "spanish_verb": "ven\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ven\u00edamos",
                "english": "We came/We used to come"
            },
            {
                "spanish_verb": "ven\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ven\u00edais",
                "english": "You all came/You all used to come"
            },
            {
                "spanish_verb": "ven\u00edan",
                "spanish_verb_person": "Ellos/Ellas ven\u00edan",
                "english": "They came/They used to come"
            },
            {
                "spanish_verb": "ven\u00edan",
                "spanish_verb_person": "Ustedes ven\u00edan",
                "english": "You all came/You all used to come (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "vendr\u00e9",
                "spanish_verb_person": "Yo vendr\u00e9",
                "english": "I will come"
            },
            {
                "spanish_verb": "vendr\u00e1s",
                "spanish_verb_person": "T\u00fa vendr\u00e1s",
                "english": "You will come"
            },
            {
                "spanish_verb": "vendr\u00e1",
                "spanish_verb_person": "\u00c9l vendr\u00e1",
                "english": "He will come"
            },
            {
                "spanish_verb": "vendr\u00e1",
                "spanish_verb_person": "Ella vendr\u00e1",
                "english": "She will come"
            },
            {
                "spanish_verb": "vendr\u00e1",
                "spanish_verb_person": "Usted vendr\u00e1",
                "english": "You will come (formal)"
            },
            {
                "spanish_verb": "vendremos",
                "spanish_verb_person": "Nosotros/Nosotras vendremos",
                "english": "We will come"
            },
            {
                "spanish_verb": "vendr\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras vendr\u00e9is",
                "english": "You all will come"
            },
            {
                "spanish_verb": "vendr\u00e1n",
                "spanish_verb_person": "Ellos/Ellas vendr\u00e1n",
                "english": "They will come"
            },
            {
                "spanish_verb": "vendr\u00e1n",
                "spanish_verb_person": "Ustedes vendr\u00e1n",
                "english": "You all will come (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "vendr\u00eda",
                "spanish_verb_person": "Yo vendr\u00eda",
                "english": "I would come"
            },
            {
                "spanish_verb": "vendr\u00edas",
                "spanish_verb_person": "T\u00fa vendr\u00edas",
                "english": "You would come"
            },
            {
                "spanish_verb": "vendr\u00eda",
                "spanish_verb_person": "\u00c9l vendr\u00eda",
                "english": "He would come"
            },
            {
                "spanish_verb": "vendr\u00eda",
                "spanish_verb_person": "Ella vendr\u00eda",
                "english": "She would come"
            },
            {
                "spanish_verb": "vendr\u00eda",
                "spanish_verb_person": "Usted vendr\u00eda",
                "english": "You would come (formal)"
            },
            {
                "spanish_verb": "vendr\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras vendr\u00edamos",
                "english": "We would come"
            },
            {
                "spanish_verb": "vendr\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras vendr\u00edais",
                "english": "You all would come"
            },
            {
                "spanish_verb": "vendr\u00edan",
                "spanish_verb_person": "Ellos/Ellas vendr\u00edan",
                "english": "They would come"
            },
            {
                "spanish_verb": "vendr\u00edan",
                "spanish_verb_person": "Ustedes vendr\u00edan",
                "english": "You all would come (formal)"
            }
        ]
    }
}
export default venir;