const estar = {
    "verb": "estar",
    "english": "to be",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "estoy",
                "spanish_verb_person": "Yo estoy",
                "english": "I am"
            },
            {
                "spanish_verb": "est\u00e1s",
                "spanish_verb_person": "T\u00fa est\u00e1s",
                "english": "You are"
            },
            {
                "spanish_verb": "est\u00e1",
                "spanish_verb_person": "\u00c9l est\u00e1",
                "english": "He is"
            },
            {
                "spanish_verb": "est\u00e1",
                "spanish_verb_person": "Ella est\u00e1",
                "english": "She is"
            },
            {
                "spanish_verb": "est\u00e1",
                "spanish_verb_person": "Usted est\u00e1",
                "english": "You are (formal)"
            },
            {
                "spanish_verb": "estamos",
                "spanish_verb_person": "Nosotros estamos",
                "english": "We are"
            },
            {
                "spanish_verb": "est\u00e1is",
                "spanish_verb_person": "Vosotros est\u00e1is",
                "english": "You all are"
            },
            {
                "spanish_verb": "est\u00e1n",
                "spanish_verb_person": "Ellos/Ellas est\u00e1n",
                "english": "They are"
            },
            {
                "spanish_verb": "est\u00e1n",
                "spanish_verb_person": "Ustedes est\u00e1n",
                "english": "You all are (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "estuve",
                "spanish_verb_person": "Yo estuve",
                "english": "I was"
            },
            {
                "spanish_verb": "estuviste",
                "spanish_verb_person": "T\u00fa estuviste",
                "english": "You were"
            },
            {
                "spanish_verb": "estuvo",
                "spanish_verb_person": "\u00c9l estuvo",
                "english": "He was"
            },
            {
                "spanish_verb": "estuvo",
                "spanish_verb_person": "Ella estuvo",
                "english": "She was"
            },
            {
                "spanish_verb": "estuvo",
                "spanish_verb_person": "Usted estuvo",
                "english": "You were (formal)"
            },
            {
                "spanish_verb": "estuvimos",
                "spanish_verb_person": "Nosotros estuvimos",
                "english": "We were"
            },
            {
                "spanish_verb": "estuvisteis",
                "spanish_verb_person": "Vosotros estuvisteis",
                "english": "You all were"
            },
            {
                "spanish_verb": "estuvieron",
                "spanish_verb_person": "Ellos/Ellas estuvieron",
                "english": "They were"
            },
            {
                "spanish_verb": "estuvieron",
                "spanish_verb_person": "Ustedes estuvieron",
                "english": "You all were (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "estaba",
                "spanish_verb_person": "Yo estaba",
                "english": "I was"
            },
            {
                "spanish_verb": "estabas",
                "spanish_verb_person": "T\u00fa estabas",
                "english": "You were"
            },
            {
                "spanish_verb": "estaba",
                "spanish_verb_person": "\u00c9l estaba",
                "english": "He was"
            },
            {
                "spanish_verb": "estaba",
                "spanish_verb_person": "Ella estaba",
                "english": "She was"
            },
            {
                "spanish_verb": "estaba",
                "spanish_verb_person": "Usted estaba",
                "english": "You were (formal)"
            },
            {
                "spanish_verb": "est\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras est\u00e1bamos",
                "english": "We were"
            },
            {
                "spanish_verb": "estabais",
                "spanish_verb_person": "Vosotros/Vosotras estabais",
                "english": "You all were"
            },
            {
                "spanish_verb": "estaban",
                "spanish_verb_person": "Ellos/Ellas estaban",
                "english": "They were"
            },
            {
                "spanish_verb": "estaban",
                "spanish_verb_person": "Ustedes estaban",
                "english": "You all were (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "estar\u00e9",
                "spanish_verb_person": "Yo estar\u00e9",
                "english": "I will be"
            },
            {
                "spanish_verb": "estar\u00e1s",
                "spanish_verb_person": "T\u00fa estar\u00e1s",
                "english": "You will be"
            },
            {
                "spanish_verb": "estar\u00e1",
                "spanish_verb_person": "\u00c9l estar\u00e1",
                "english": "He will be"
            },
            {
                "spanish_verb": "estar\u00e1",
                "spanish_verb_person": "Ella estar\u00e1",
                "english": "She will be"
            },
            {
                "spanish_verb": "estar\u00e1",
                "spanish_verb_person": "Usted estar\u00e1",
                "english": "You will be (formal)"
            },
            {
                "spanish_verb": "estaremos",
                "spanish_verb_person": "Nosotros/Nosotras estaremos",
                "english": "We will be"
            },
            {
                "spanish_verb": "estar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras estar\u00e9is",
                "english": "You all will be"
            },
            {
                "spanish_verb": "estar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas estar\u00e1n",
                "english": "They will be"
            },
            {
                "spanish_verb": "estar\u00e1n",
                "spanish_verb_person": "Ustedes estar\u00e1n",
                "english": "You all will be (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "estar\u00eda",
                "spanish_verb_person": "Yo estar\u00eda",
                "english": "I would be"
            },
            {
                "spanish_verb": "estar\u00edas",
                "spanish_verb_person": "T\u00fa estar\u00edas",
                "english": "You would be"
            },
            {
                "spanish_verb": "estar\u00eda",
                "spanish_verb_person": "\u00c9l estar\u00eda",
                "english": "He would be"
            },
            {
                "spanish_verb": "estar\u00eda",
                "spanish_verb_person": "Ella estar\u00eda",
                "english": "She would be"
            },
            {
                "spanish_verb": "estar\u00eda",
                "spanish_verb_person": "Usted estar\u00eda",
                "english": "You would be (formal)"
            },
            {
                "spanish_verb": "estar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras estar\u00edamos",
                "english": "We would be"
            },
            {
                "spanish_verb": "estar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras estar\u00edais",
                "english": "You all would be"
            },
            {
                "spanish_verb": "estar\u00edan",
                "spanish_verb_person": "Ellos/Ellas estar\u00edan",
                "english": "They would be"
            },
            {
                "spanish_verb": "estar\u00edan",
                "spanish_verb_person": "Ustedes estar\u00edan",
                "english": "You all would be (formal)"
            }
        ]
    }
}
export default estar;