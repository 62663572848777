import React from 'react';
import styles from './ControlCenter.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTenseControl, toggleOtherControl } from '../../redux/controlSlice.js';

function TenseConrols({controls}) {
  const dispatch = useDispatch();
  const handleCheckboxChange = id => {
    dispatch({ type: toggleTenseControl.type, payload: id });
  };
  return (
    <div className={styles.contolArea}>
      {controls.map(control => (
        <div key={control.id} className={styles.checkboxContol}>
          <input
            type="checkbox"
            className={styles.checkboxInput}
            id={control.id}
            checked={control.checked}
            onChange={() => handleCheckboxChange(control.id)}
          />
          <label htmlFor={control.id} className={styles.checkboxLabel}>
            {control.label}
          </label>
        </div>
      ))}
    </div>
  )
}

function OtherConrols({controls}) {
  const dispatch = useDispatch();
  const handleOtherControl = id => {
    dispatch({ type: toggleOtherControl.type, payload: id });
  }
  return (
    <div className={styles.contolArea}>
      {controls.map(control => (
        <div key={control.id} className={styles.checkboxContol}>
          <input
            type="checkbox"
            className={styles.checkboxInput}
            id={control.id}
            checked={control.checked}
            onChange={() => handleOtherControl(control.id)}
          />
          <label htmlFor={control.id} className={styles.checkboxLabel}>
            {control.label}
          </label>
        </div>
      ))}
    </div>
  )
}

function ControlCenter(props) {
  const controlSlice = useSelector(state => state.controlSlice);
  const tenseControls = controlSlice.tenseControls;
  const otherControls = controlSlice.otherControls;
  return (
    <div className={styles['control-center']}>
      <TenseConrols controls={tenseControls}/>
      <OtherConrols controls={otherControls}/>
    </div>
  )
}

export default ControlCenter;