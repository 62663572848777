const llegar = {
    "verb": "llegar",
    "english": "to arrive",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "llego",
                "spanish_verb_person": "Yo llego",
                "english": "I arrive"
            },
            {
                "spanish_verb": "llegas",
                "spanish_verb_person": "T\u00fa llegas",
                "english": "You arrive"
            },
            {
                "spanish_verb": "llega",
                "spanish_verb_person": "\u00c9l llega",
                "english": "He arrives"
            },
            {
                "spanish_verb": "llega",
                "spanish_verb_person": "Ella llega",
                "english": "She arrives"
            },
            {
                "spanish_verb": "llega",
                "spanish_verb_person": "Usted llega",
                "english": "You arrive (formal)"
            },
            {
                "spanish_verb": "llegamos",
                "spanish_verb_person": "Nosotros/Nosotras llegamos",
                "english": "We arrive"
            },
            {
                "spanish_verb": "lleg\u00e1is",
                "spanish_verb_person": "Vosotros/Vosotras lleg\u00e1is",
                "english": "You all arrive"
            },
            {
                "spanish_verb": "llegan",
                "spanish_verb_person": "Ellos/Ellas llegan",
                "english": "They arrive"
            },
            {
                "spanish_verb": "llegan",
                "spanish_verb_person": "Ustedes llegan",
                "english": "You all arrive (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "llegu\u00e9",
                "spanish_verb_person": "Yo llegu\u00e9",
                "english": "I arrived"
            },
            {
                "spanish_verb": "llegaste",
                "spanish_verb_person": "T\u00fa llegaste",
                "english": "You arrived"
            },
            {
                "spanish_verb": "lleg\u00f3",
                "spanish_verb_person": "\u00c9l lleg\u00f3",
                "english": "He arrived"
            },
            {
                "spanish_verb": "lleg\u00f3",
                "spanish_verb_person": "Ella lleg\u00f3",
                "english": "She arrived"
            },
            {
                "spanish_verb": "lleg\u00f3",
                "spanish_verb_person": "Usted lleg\u00f3",
                "english": "You arrived (formal)"
            },
            {
                "spanish_verb": "llegamos",
                "spanish_verb_person": "Nosotros/Nosotras llegamos",
                "english": "We arrived"
            },
            {
                "spanish_verb": "llegasteis",
                "spanish_verb_person": "Vosotros/Vosotras llegasteis",
                "english": "You all arrived"
            },
            {
                "spanish_verb": "llegaron",
                "spanish_verb_person": "Ellos/Ellas llegaron",
                "english": "They arrived"
            },
            {
                "spanish_verb": "llegaron",
                "spanish_verb_person": "Ustedes llegaron",
                "english": "You all arrived (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "llegaba",
                "spanish_verb_person": "Yo llegaba",
                "english": "I arrived/I used to arrive"
            },
            {
                "spanish_verb": "llegabas",
                "spanish_verb_person": "T\u00fa llegabas",
                "english": "You arrived/You used to arrive"
            },
            {
                "spanish_verb": "llegaba",
                "spanish_verb_person": "\u00c9l llegaba",
                "english": "He arrived"
            },
            {
                "spanish_verb": "llegaba",
                "spanish_verb_person": "Ella llegaba",
                "english": "She arrived"
            },
            {
                "spanish_verb": "llegaba",
                "spanish_verb_person": "Usted llegaba",
                "english": "You arrived (formal)"
            },
            {
                "spanish_verb": "lleg\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras lleg\u00e1bamos",
                "english": "We arrived/We used to arrive"
            },
            {
                "spanish_verb": "llegabais",
                "spanish_verb_person": "Vosotros/Vosotras llegabais",
                "english": "You all arrived/You all used to arrive"
            },
            {
                "spanish_verb": "llegaban",
                "spanish_verb_person": "Ellos/Ellas llegaban",
                "english": "They arrived"
            },
            {
                "spanish_verb": "llegaban",
                "spanish_verb_person": "Ustedes llegaban",
                "english": "You all arrived (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "llegar\u00e9",
                "spanish_verb_person": "Yo llegar\u00e9",
                "english": "I will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1s",
                "spanish_verb_person": "T\u00fa llegar\u00e1s",
                "english": "You will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1",
                "spanish_verb_person": "\u00c9l llegar\u00e1",
                "english": "He will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1",
                "spanish_verb_person": "Ella llegar\u00e1",
                "english": "She will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1",
                "spanish_verb_person": "Usted llegar\u00e1",
                "english": "You will arrive (formal)"
            },
            {
                "spanish_verb": "llegaremos",
                "spanish_verb_person": "Nosotros/Nosotras llegaremos",
                "english": "We will arrive"
            },
            {
                "spanish_verb": "llegar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras llegar\u00e9is",
                "english": "You all will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas llegar\u00e1n",
                "english": "They will arrive"
            },
            {
                "spanish_verb": "llegar\u00e1n",
                "spanish_verb_person": "Ustedes llegar\u00e1n",
                "english": "You all will arrive (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "llegar\u00eda",
                "spanish_verb_person": "Yo llegar\u00eda",
                "english": "I would arrive"
            },
            {
                "spanish_verb": "llegar\u00edas",
                "spanish_verb_person": "T\u00fa llegar\u00edas",
                "english": "You would arrive"
            },
            {
                "spanish_verb": "llegar\u00eda",
                "spanish_verb_person": "\u00c9l llegar\u00eda",
                "english": "He would arrive"
            },
            {
                "spanish_verb": "llegar\u00eda",
                "spanish_verb_person": "Ella llegar\u00eda",
                "english": "She would arrive"
            },
            {
                "spanish_verb": "llegar\u00eda",
                "spanish_verb_person": "Usted llegar\u00eda",
                "english": "You would arrive (formal)"
            },
            {
                "spanish_verb": "llegar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras llegar\u00edamos",
                "english": "We would arrive"
            },
            {
                "spanish_verb": "llegar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras llegar\u00edais",
                "english": "You all would arrive"
            },
            {
                "spanish_verb": "llegar\u00edan",
                "spanish_verb_person": "Ellos/Ellas llegar\u00edan",
                "english": "They would arrive"
            },
            {
                "spanish_verb": "llegar\u00edan",
                "spanish_verb_person": "Ustedes llegar\u00edan",
                "english": "You all would arrive (formal)"
            }
        ]
    }
}
export default llegar;