import React, { useEffect, useState } from 'react';
import styles from './Card.module.scss';
import cn from 'classnames';

function Card({spanish_verb_person, english, tense, flipped}) {
  const [frontForward, setFrontForward] = useState(flipped ? false : true);
  useEffect(() => {
    setFrontForward(!flipped);
  }, [flipped]);

  const flipCard = () => {
    setFrontForward(!frontForward);
  };

  const classNames = [styles.card];
  if (!frontForward) {
    classNames.push(styles.flipped);
  }
  const card_bg = `card_bg_${tense}`;
  const card_bg_back = `card_bg_${tense}_back`;
  return (
    <div className={cn(classNames)} onClick={flipCard}>
      <div className={styles["card-inner"]}>
        <div className={cn(styles["flip-card-front"], card_bg)}>
          <h2>{spanish_verb_person}</h2>
        </div>
        <div className={cn(styles["flip-card-back"], card_bg_back)}>
          <span className={styles["tense"]}>{tense}</span>
          <div>
            {english.split("/").map((eng) => <span className={styles["eng"]} key={eng}>{eng}</span>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
