const encontrar = {
    "verb": "encontrar",
    "english": "to find",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "encuentro",
                "spanish_verb_person": "Yo encuentro",
                "english": "I find"
            },
            {
                "spanish_verb": "encuentras",
                "spanish_verb_person": "T\u00fa encuentras",
                "english": "You find"
            },
            {
                "spanish_verb": "encuentra",
                "spanish_verb_person": "\u00c9l encuentra",
                "english": "He finds"
            },
            {
                "spanish_verb": "encuentra",
                "spanish_verb_person": "Ella encuentra",
                "english": "She finds"
            },
            {
                "spanish_verb": "encuentra",
                "spanish_verb_person": "Usted encuentra",
                "english": "You find (formal)"
            },
            {
                "spanish_verb": "encontramos",
                "spanish_verb_person": "Nosotros/Nosotras encontramos",
                "english": "We find"
            },
            {
                "spanish_verb": "encontr\u00e1is",
                "spanish_verb_person": "Vosotros/Vosotras encontr\u00e1is",
                "english": "You all find"
            },
            {
                "spanish_verb": "encuentran",
                "spanish_verb_person": "Ellos/Ellas encuentran",
                "english": "They find"
            },
            {
                "spanish_verb": "encuentran",
                "spanish_verb_person": "Ustedes encuentran",
                "english": "You all find (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "encontr\u00e9",
                "spanish_verb_person": "Yo encontr\u00e9",
                "english": "I found"
            },
            {
                "spanish_verb": "encontraste",
                "spanish_verb_person": "T\u00fa encontraste",
                "english": "You found"
            },
            {
                "spanish_verb": "encontr\u00f3",
                "spanish_verb_person": "\u00c9l encontr\u00f3",
                "english": "He found"
            },
            {
                "spanish_verb": "encontr\u00f3",
                "spanish_verb_person": "Ella encontr\u00f3",
                "english": "She found"
            },
            {
                "spanish_verb": "encontr\u00f3",
                "spanish_verb_person": "Usted encontr\u00f3",
                "english": "You found (formal)"
            },
            {
                "spanish_verb": "encontramos",
                "spanish_verb_person": "Nosotros/Nosotras encontramos",
                "english": "We found"
            },
            {
                "spanish_verb": "encontrasteis",
                "spanish_verb_person": "Vosotros/Vosotras encontrasteis",
                "english": "You all found"
            },
            {
                "spanish_verb": "encontraron",
                "spanish_verb_person": "Ellos/Ellas encontraron",
                "english": "They found"
            },
            {
                "spanish_verb": "encontraron",
                "spanish_verb_person": "Ustedes encontraron",
                "english": "You all found (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "encontraba",
                "spanish_verb_person": "Yo encontraba",
                "english": "I found/I used to find"
            },
            {
                "spanish_verb": "encontrabas",
                "spanish_verb_person": "T\u00fa encontrabas",
                "english": "You found/You used to find"
            },
            {
                "spanish_verb": "encontraba",
                "spanish_verb_person": "\u00c9l encontraba",
                "english": "He found/He used to find"
            },
            {
                "spanish_verb": "encontraba",
                "spanish_verb_person": "Ella encontraba",
                "english": "She found/She used to find"
            },
            {
                "spanish_verb": "encontraba",
                "spanish_verb_person": "Usted encontraba",
                "english": "You found/You used to find (formal)"
            },
            {
                "spanish_verb": "encontr\u00e1bamos",
                "spanish_verb_person": "Nosotros/Nosotras encontr\u00e1bamos",
                "english": "We found/We used to find"
            },
            {
                "spanish_verb": "encontrabais",
                "spanish_verb_person": "Vosotros/Vosotras encontrabais",
                "english": "You all found/You all used to find"
            },
            {
                "spanish_verb": "encontraban",
                "spanish_verb_person": "Ellos/Ellas encontraban",
                "english": "They found/They used to find"
            },
            {
                "spanish_verb": "encontraban",
                "spanish_verb_person": "Ustedes encontraban",
                "english": "You all found/You all used to find (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "encontrar\u00e9",
                "spanish_verb_person": "Yo encontrar\u00e9",
                "english": "I will find"
            },
            {
                "spanish_verb": "encontrar\u00e1s",
                "spanish_verb_person": "T\u00fa encontrar\u00e1s",
                "english": "You will find"
            },
            {
                "spanish_verb": "encontrar\u00e1",
                "spanish_verb_person": "\u00c9l encontrar\u00e1",
                "english": "He will find"
            },
            {
                "spanish_verb": "encontrar\u00e1",
                "spanish_verb_person": "Ella encontrar\u00e1",
                "english": "She will find"
            },
            {
                "spanish_verb": "encontrar\u00e1",
                "spanish_verb_person": "Usted encontrar\u00e1",
                "english": "You will find (formal)"
            },
            {
                "spanish_verb": "encontraremos",
                "spanish_verb_person": "Nosotros/Nosotras encontraremos",
                "english": "We will find"
            },
            {
                "spanish_verb": "encontrar\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras encontrar\u00e9is",
                "english": "You all will find"
            },
            {
                "spanish_verb": "encontrar\u00e1n",
                "spanish_verb_person": "Ellos/Ellas encontrar\u00e1n",
                "english": "They will find"
            },
            {
                "spanish_verb": "encontrar\u00e1n",
                "spanish_verb_person": "Ustedes encontrar\u00e1n",
                "english": "You all will find (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "encontrar\u00eda",
                "spanish_verb_person": "Yo encontrar\u00eda",
                "english": "I would find"
            },
            {
                "spanish_verb": "encontrar\u00edas",
                "spanish_verb_person": "T\u00fa encontrar\u00edas",
                "english": "You would find"
            },
            {
                "spanish_verb": "encontrar\u00eda",
                "spanish_verb_person": "\u00c9l encontrar\u00eda",
                "english": "He would find"
            },
            {
                "spanish_verb": "encontrar\u00eda",
                "spanish_verb_person": "Ella encontrar\u00eda",
                "english": "She would find"
            },
            {
                "spanish_verb": "encontrar\u00eda",
                "spanish_verb_person": "Usted encontrar\u00eda",
                "english": "You would find (formal)"
            },
            {
                "spanish_verb": "encontrar\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras encontrar\u00edamos",
                "english": "We would find"
            },
            {
                "spanish_verb": "encontrar\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras encontrar\u00edais",
                "english": "You all would find"
            },
            {
                "spanish_verb": "encontrar\u00edan",
                "spanish_verb_person": "Ellos/Ellas encontrar\u00edan",
                "english": "They would find"
            },
            {
                "spanish_verb": "encontrar\u00edan",
                "spanish_verb_person": "Ustedes encontrar\u00edan",
                "english": "You all would find (formal)"
            }
        ]
    }
}
export default encontrar;