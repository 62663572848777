const ir = {
    "verb": "ir",
    "english": "to go",
    "conjugations": {
        "present": [
            {
                "spanish_verb": "voy",
                "spanish_verb_person": "Yo voy",
                "english": "I go"
            },
            {
                "spanish_verb": "vas",
                "spanish_verb_person": "T\u00fa vas",
                "english": "You go"
            },
            {
                "spanish_verb": "va",
                "spanish_verb_person": "\u00c9l va",
                "english": "He goes"
            },
            {
                "spanish_verb": "va",
                "spanish_verb_person": "Ella va",
                "english": "She goes"
            },
            {
                "spanish_verb": "va",
                "spanish_verb_person": "Usted va",
                "english": "You go (formal)"
            },
            {
                "spanish_verb": "vamos",
                "spanish_verb_person": "Nosotros/Nosotras vamos",
                "english": "We go"
            },
            {
                "spanish_verb": "vais",
                "spanish_verb_person": "Vosotros/Vosotras vais",
                "english": "You all go"
            },
            {
                "spanish_verb": "van",
                "spanish_verb_person": "Ellos/Ellas van",
                "english": "They go"
            },
            {
                "spanish_verb": "van",
                "spanish_verb_person": "Ustedes van",
                "english": "You all go (formal)"
            }
        ],
        "preterite": [
            {
                "spanish_verb": "fui",
                "spanish_verb_person": "Yo fui",
                "english": "I went"
            },
            {
                "spanish_verb": "fuiste",
                "spanish_verb_person": "T\u00fa fuiste",
                "english": "You went"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "\u00c9l fue",
                "english": "He went"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "Ella fue",
                "english": "She went"
            },
            {
                "spanish_verb": "fue",
                "spanish_verb_person": "Usted fue",
                "english": "You went (formal)"
            },
            {
                "spanish_verb": "fuimos",
                "spanish_verb_person": "Nosotros/Nosotras fuimos",
                "english": "We went"
            },
            {
                "spanish_verb": "fuisteis",
                "spanish_verb_person": "Vosotros/Vosotras fuisteis",
                "english": "You all went"
            },
            {
                "spanish_verb": "fueron",
                "spanish_verb_person": "Ellos/Ellas fueron",
                "english": "They went"
            },
            {
                "spanish_verb": "fueron",
                "spanish_verb_person": "Ustedes fueron",
                "english": "You all went (formal)"
            }
        ],
        "imperfect": [
            {
                "spanish_verb": "iba",
                "spanish_verb_person": "Yo iba",
                "english": "I was going"
            },
            {
                "spanish_verb": "ibas",
                "spanish_verb_person": "T\u00fa ibas",
                "english": "You were going"
            },
            {
                "spanish_verb": "iba",
                "spanish_verb_person": "\u00c9l iba",
                "english": "He was going"
            },
            {
                "spanish_verb": "iba",
                "spanish_verb_person": "Ella iba",
                "english": "She was going"
            },
            {
                "spanish_verb": "iba",
                "spanish_verb_person": "Usted iba",
                "english": "You were going (formal)"
            },
            {
                "spanish_verb": "\u00edbamos",
                "spanish_verb_person": "Nosotros/Nosotras \u00edbamos",
                "english": "We were going"
            },
            {
                "spanish_verb": "ibais",
                "spanish_verb_person": "Vosotros/Vosotras ibais",
                "english": "You all were going"
            },
            {
                "spanish_verb": "iban",
                "spanish_verb_person": "Ellos/Ellas iban",
                "english": "They were going"
            },
            {
                "spanish_verb": "iban",
                "spanish_verb_person": "Ustedes iban",
                "english": "You all were going (formal)"
            }
        ],
        "future": [
            {
                "spanish_verb": "ir\u00e9",
                "spanish_verb_person": "Yo ir\u00e9",
                "english": "I will go"
            },
            {
                "spanish_verb": "ir\u00e1s",
                "spanish_verb_person": "T\u00fa ir\u00e1s",
                "english": "You will go"
            },
            {
                "spanish_verb": "ir\u00e1",
                "spanish_verb_person": "\u00c9l ir\u00e1",
                "english": "He will go"
            },
            {
                "spanish_verb": "ir\u00e1",
                "spanish_verb_person": "Ella ir\u00e1",
                "english": "She will go"
            },
            {
                "spanish_verb": "ir\u00e1",
                "spanish_verb_person": "Usted ir\u00e1",
                "english": "You will go (formal)"
            },
            {
                "spanish_verb": "iremos",
                "spanish_verb_person": "Nosotros/Nosotras iremos",
                "english": "We will go"
            },
            {
                "spanish_verb": "ir\u00e9is",
                "spanish_verb_person": "Vosotros/Vosotras ir\u00e9is",
                "english": "You all will go"
            },
            {
                "spanish_verb": "ir\u00e1n",
                "spanish_verb_person": "Ellos/Ellas ir\u00e1n",
                "english": "They will go"
            },
            {
                "spanish_verb": "ir\u00e1n",
                "spanish_verb_person": "Ustedes ir\u00e1n",
                "english": "You all will go (formal)"
            }
        ],
        "conditional": [
            {
                "spanish_verb": "ir\u00eda",
                "spanish_verb_person": "Yo ir\u00eda",
                "english": "I would go"
            },
            {
                "spanish_verb": "ir\u00edas",
                "spanish_verb_person": "T\u00fa ir\u00edas",
                "english": "You would go"
            },
            {
                "spanish_verb": "ir\u00eda",
                "spanish_verb_person": "\u00c9l ir\u00eda",
                "english": "He would go"
            },
            {
                "spanish_verb": "ir\u00eda",
                "spanish_verb_person": "Ella ir\u00eda",
                "english": "She would go"
            },
            {
                "spanish_verb": "ir\u00eda",
                "spanish_verb_person": "Usted ir\u00eda",
                "english": "You would go (formal)"
            },
            {
                "spanish_verb": "ir\u00edamos",
                "spanish_verb_person": "Nosotros/Nosotras ir\u00edamos",
                "english": "We would go"
            },
            {
                "spanish_verb": "ir\u00edais",
                "spanish_verb_person": "Vosotros/Vosotras ir\u00edais",
                "english": "You all would go"
            },
            {
                "spanish_verb": "ir\u00edan",
                "spanish_verb_person": "Ellos/Ellas ir\u00edan",
                "english": "They would go"
            },
            {
                "spanish_verb": "ir\u00edan",
                "spanish_verb_person": "Ustedes ir\u00edan",
                "english": "You all would go (formal)"
            }
        ]
    }
}
export default ir;